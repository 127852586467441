import { useEffect, useState } from 'react'
import {  useMutation, useQuery } from '@apollo/client'
import { useHistory, useParams } from 'react-router';
import {EDIT_PRINTER_ON_DEVICE, GET_ONE_DEVICE, REMOVE_PRINTER_FROM_DEVICE, USERME} from '../../../graphql/queries'
import LogsTable from '../LogsTable';
import Modal from 'react-modal';
import {IDevice, IPrinter, supportedPrinters,IPrinterInput, IUser} from '../../../graphql/types'
import { ENV } from "../../../config/index"
import {exampleCommandDailyClousure, exampleCommandOneCoffee, exampleCommandReprintLastRecipt, exampleNonFiscaleMessage} from "./commandsExamples"
import { DB_AUTH } from '../../../libs/stores/db/auth';
const modalStyle = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

Modal.setAppElement('#root');

const checkJSON = (text: string) => {
  try {
    JSON.parse(text)    
  } catch (error) {
    return 'Sintatti non corretta'    
  }
  return 'Sintassi corretta'
}

export default function UpdatePrinter (props: any) {
  const history = useHistory();
  const { _id }: any = useParams();
  const { printerId }: any = useParams();
  const permissions = DB_AUTH.get().user?.auth?.permissions || []
  const isRoot = permissions.includes('root')
  const [modalIsOpen,setIsOpen] = useState(false);
  const [updateIsOpen, setUpdateIsOpen] = useState(false);
  const [updateMessage, setUpdateMessage] = useState('')
  const [showAuth, setShowAuth] = useState(false)
  const userMeResult  = useQuery(USERME,{fetchPolicy:"cache-and-network", onCompleted: ()=>{console.log('USERME Completed', userMeResult.data?.userMe)}})  
  const [PRINTER,setPrinter]: [IPrinter,any] = useState({
    _id: "",
    name: "",
    brand: "",
    ip: "",
    port: "",
    url: "",
    isSSL: true,
    isFiscal: false,
    automaticClousure: false,
    model: "",
    logs: [],
    onlineStatus: false,
    printerAuth: {
      username: "",
      password: ""
    }
  })
  const [DEVICE, setDevice]: [IDevice,any] = useState({
    _id: "",
    deviceApiAuthCode: "",
    logs: [],
    name: "",
    printers: [],
    serial: "",
    socket: ""
  })
  const [jsonCorrect, setJsonCorrect] = useState('Comando Corretto')
  const [jsonCommand, setJsonCommand] = useState('{}')
  const [jsonCommandResult, setJsonCommandResult] = useState('')
  const [directCommand, setDirectCommand] = useState('')
  const [directCommandResult, setDirectCommandResult] = useState('')

  const [ editPrinter ] = useMutation(EDIT_PRINTER_ON_DEVICE, {onCompleted: ()=> {
    console.log('Printer edited');
    setUpdateMessage('Stampante modificata correttamente')
    setUpdateIsOpen(false)
  }, onError: (err) => {
    console.log(`Error in editing the printer: ${err}`)
    setUpdateMessage(`Errore nella modifica della stampante: ${err}`)
    setUpdateIsOpen(false)
    }
  });

  const [removePrinter] = useMutation(REMOVE_PRINTER_FROM_DEVICE,{onCompleted: (res) => {
    console.log('Printer Removed',res)
    history.push(`/device/${_id}`)
  }, onError: (err) => console.log(`Error in Removing the printer: ${err}`)});

  let { loading: queryLoading, error: queryError, data, startPolling, stopPolling } = useQuery(GET_ONE_DEVICE, {
    fetchPolicy:"cache-and-network",
    variables: { id: _id },
    onCompleted: async () => {
      if (data.Device) {
        // setDevice(data.Device)
        // //estrapolo e setto printer
        // const printer = (data.Device.printers) ? data.Device.printers.find((printer: any) => {
        //   if (printer._id === printerId) return printer
        // }) : null
        // if (printer) {
        //   setPrinter(printer)
        // } else {
        //   console.log('Printer not Found')
        // }
        console.log(`GET_ONE_DEVICE completed`)
      } else console.log(`GET_ONE_DEVICE error, Device not found`)
    },
    // pollInterval: 5000
  })
  useEffect(() => {
    console.log('startPooling')
    startPolling(5000)
  return () => {
    console.log('stopPooling')
   stopPolling()
  }
}, [startPolling, stopPolling])

useEffect(() => {
  if (data){
    setDevice(data.Device)
    const printer = (data.Device.printers) ? data.Device.printers.find((printer: any) => {
      if (printer._id === printerId) return printer
        else return null
    }) : null
    if (printer) {
      setPrinter(printer)
      if (printer.brand.toUpperCase() === "CUSTOM") {
        setShowAuth(true)
      }
    } else {
      console.log('Printer not Found')
    }
  }
}, [data])

  if (queryError) {
    console.log(`Error: ${queryError}`)
    return <div>Error loading the device..</div>
  }
  if (queryLoading) return <div>Loading</div>

  // const device = data.Device as IDevice
  console.log("device",DEVICE)
  console.log("printer",PRINTER)

  const printerStatus = PRINTER.onlineStatus ? (
    <>
      <i className='fas fa-circle text-green-500 mr-2'></i> online
    </>
  ) : (
    <>
      <i className='fas fa-circle text-red-500 mr-2'></i> offline
    </>
  )

  const handleSendCommand = async  (command: string) => {
    try {
      console.log('Send Command')
      console.log(`Printer: ${PRINTER._id}`)
      console.log(`Device API: ${DEVICE.deviceApiAuthCode}`)
      console.log(`User API: ${userMeResult.data?.userMe.userApiAuthCode}`)
      console.log(`Command:`)
      console.log(command)
      const xmlHttp = new XMLHttpRequest();
      if (PRINTER.isFiscal) {
        xmlHttp.open("POST",ENV.backend.APIuri + `/fiscalreceipt/${PRINTER._id}`,false)
      } else {
        xmlHttp.open("POST",ENV.backend.APIuri + `/nonfiscalreceipt/${PRINTER._id}`,false)
      }
      xmlHttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xmlHttp.setRequestHeader("userkey",userMeResult.data?.userMe.userApiAuthCode)
      xmlHttp.setRequestHeader("devicekey",DEVICE.deviceApiAuthCode)
      xmlHttp.send(command)
      setJsonCommandResult(xmlHttp.responseText)
    } catch (error) {
      console.log('handleSendCommand error', error)
    }
  }  
  const handleSendDirectCommand = async  (command: string) => {
    try {
      setDirectCommandResult("")
      console.log('Send Command')
      console.log(`Printer: ${PRINTER._id}`)
      console.log(`Device API: ${DEVICE.deviceApiAuthCode}`)
      console.log(`User API: ${userMeResult.data?.userMe.userApiAuthCode}`)
      console.log(`Command:`)
      console.log(command)
      const xmlHttp = new XMLHttpRequest();
      if (PRINTER.isFiscal) {
        xmlHttp.open("POST",ENV.backend.APIuri + `/syncDirectXML/${PRINTER._id}`,false)
      } else {
        xmlHttp.open("POST",ENV.backend.APIuri + `/nonFiscalSyncDirectXML/${PRINTER._id}`,false)
      }
      xmlHttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xmlHttp.setRequestHeader("userkey",userMeResult.data?.userMe.userApiAuthCode)
      xmlHttp.setRequestHeader("devicekey",DEVICE.deviceApiAuthCode)
      xmlHttp.send(JSON.stringify({data : command}))
      setDirectCommandResult(xmlHttp.responseText)
    } catch (error) {
      console.log('handleSendDirectCommand error', error)
    }
  }
  let examples = <></>
  if (PRINTER.isFiscal) {
    examples = <div className="flex flex-col w-1/4 pt-4">
    <div className='text-center'>
      Esempi
    </div>
    <div className='text-right pt-2'>
      <div>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={(event)=>{
              setJsonCommand(exampleCommandDailyClousure)
            }}>
            Chiusura Giornaliera
        </button>
      </div>
      <div className="pt-2">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={(event)=>{
              setJsonCommand(exampleCommandOneCoffee)
            }}>
            1 caffè
        </button>
      </div>
      <div className="pt-2">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={(event)=>{
              setJsonCommand(exampleCommandReprintLastRecipt)
            }}>
            Ristampa ultimo scontrino
        </button>
      </div>
      <div className="pt-8">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={(event)=>{
              setJsonCommand('{}')
            }}>
            Svuota
        </button>
      </div>
    </div>
  </div>
  } else {
    examples = <div className="flex flex-col w-1/4 pt-4">
    <div className='text-center'>
      Esempi
    </div>
    <div className='text-right pt-2'>
      <div>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={(event)=>{
              setJsonCommand(exampleNonFiscaleMessage)
            }}>
            Scrivi messaggio
        </button>
      </div>
    </div>
  </div>
  }
  return (
    <>
          <Modal
          isOpen={modalIsOpen}
          style={modalStyle}
          contentLabel="Example Modal"
        >
          <div>
            <div className="text-center pb-2">
              Rimozione Stampante
            </div>
            <div className="flex">
              <div className="pr-2">
              <button className="flex-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => 
                setIsOpen(false)}>
                  Annulla
              </button>
              </div>
              <div>
              <button className="flex-1 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={()=>{
                removePrinter({variables: {
                  deviceId: _id,
                  printerId: PRINTER._id
                }})
                }}>
                Rimuovi
              </button>
              </div>
            </div>
          </div>
        </Modal>
      <div className='flex flex-wrap w-full'>
        <div className='w-full mb-12 px-4'>
          <div
            className={
              'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' 
            }
          >
            <div className='rounded-t mb-0 px-4 py-3 border-0'>
              <div className='flex flex-wrap items-center'>
                <div className='flex flex-row justify-between relative w-full px-4 max-w-full flex-grow flex-1'>
                  <div
                      className={
                        'font-semibold text-lg ' 
                      }
                    >
                      Stampante su dispositivo: {DEVICE.name}
                  </div>
                  <div
                    className={
                      'font-semibold text-lg ' 
                    }
                  >
                    Id stampante: {PRINTER._id}
                  </div>
                  <div className ="px-4 flex flex-row">
                    <div>{printerStatus}</div>
                  </div>
                </div>
                <div className='block w-full overflow-x-auto p-2'>
                  <div className='flex flex-row justify-between bg-blue-500 rounded-t-md p-2 cursor-pointer' onClick={() => {
                    // azzero se sto aprendo
                    if (!updateIsOpen) setUpdateMessage('')
                    setUpdateIsOpen(!updateIsOpen)
                  }}>
                    <div>
                      Modifica Stampante
                    </div>
                    <div className='font-thin'>
                      {updateMessage}
                    </div>
                    <div>
                      {
                        (updateIsOpen) ? (<svg className='w-8 h-8' fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                      </svg>): (<svg className='w-8 h-8' fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>)
                      }
                    </div>
                  </div>
                  <div className='flex flex-col pt-2'>
                    {/* Edit Printer */}
                    <div className={(updateIsOpen)? 'flex flex-col space-y-2' : 'hidden'}>
                      {/* nome, ip, port, url */}
                      <div className='flex flex-row'>
                        <div className='w-1/3 text-center uppercase'>
                          <label
                            className='block uppercase text-gray-700 text-xs font-bold mb-2'
                            htmlFor='grid-password'
                          >
                            Nome
                          </label>
                          <input
                            type='text'
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                            placeholder='Nome'
                            value={PRINTER.name}
                            onChange={e => {
                              setPrinter({...PRINTER, name: e.target.value})
                            }}
                          />
                        </div>
                        <div className='w-1/6 pl-2  text-center uppercase'>
                          <label
                            className='block uppercase text-gray-700 text-xs font-bold mb-2'
                            htmlFor='grid-password'
                          >
                            Ip
                          </label>
                          <input
                            type='text'
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                            placeholder='Ip'
                            value={PRINTER.ip}
                            onChange={e => {
                              setPrinter({...PRINTER, ip: e.target.value})
                            }}
                          />
                        </div>
                        <div className='w-1/6 pl-2  text-center uppercase'>
                          <label
                            className='block uppercase text-gray-700 text-xs font-bold mb-2'
                            htmlFor='grid-password'
                          >
                            Port
                          </label>
                          <input
                            type='text'
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                            placeholder='Port'
                            value={PRINTER.port}
                            onChange={e => {
                              setPrinter({...PRINTER, port: e.target.value})
                            }}
                          />
                        </div>
                        <div className='w-1/6 pl-2  text-center uppercase'>
                          <label
                            className='block uppercase text-gray-700 text-xs font-bold mb-2'
                            htmlFor='grid-password'
                          >
                            Url
                          </label>
                          <input
                            type='text'
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                            placeholder='Url'
                            value={PRINTER.url}
                            onChange={e => {
                              setPrinter({...PRINTER, url: e.target.value})
                            }}
                          />
                        </div>
                      </div>
                      {/* ssl, fiscal, chius autom */}
                      <div className='flex flex-row'>
                        <div className='w-1/6 pl-2  text-center uppercase'>
                          <label
                            className='block uppercase text-gray-700 text-xs font-bold mb-2'
                            htmlFor='grid-password'
                          >
                            SSL
                          </label>
                          <input
                            type='checkbox'
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                            placeholder='SSL'
                            checked={PRINTER.isSSL}
                            onChange={e => {
                              setPrinter({...PRINTER, isSSL: e.target.checked})
                            }}
                          />
                        </div>
                        <div className='w-1/6 pl-2  text-center uppercase'>
                          <label
                            className='block uppercase text-gray-700 text-xs font-bold mb-2'
                            htmlFor='grid-password'
                          >
                            Fiscale
                          </label>
                          <input
                            type='checkbox'
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                            placeholder='Fiscale'
                            checked={PRINTER.isFiscal}
                            onChange={e => {
                              setPrinter({...PRINTER, isFiscal: e.target.checked})
                            }}
                          />
                        </div>
                        <div className='w-1/6 pl-2  text-center uppercase'>
                          <label
                            className='block uppercase text-gray-700 text-xs font-bold mb-2'
                            htmlFor='grid-password'
                          >
                            Chius. Automatica
                          </label>
                          <input
                            type='checkbox'
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                            placeholder='Chiusura Automatica'
                            checked={PRINTER.automaticClousure}
                            onChange={e => {
                              setPrinter({...PRINTER, automaticClousure: e.target.checked})
                            }}
                          />
                        </div>
                      </div>
                      {/* brand, model */}
                      <div className='flex flex-row'>
                        <div className="w-1/3 pl-2 text-center uppercase">
                          <label
                            className='block uppercase text-gray-700 text-xs font-bold mb-2'
                            htmlFor='grid-password'
                          >
                            Marca e modello
                          </label>
                          <div className="flex flex-row">
                            <div className="w-1/2 pr-1">
                              <select 
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                onChange={(event) => {
                                  setPrinter({...PRINTER, brand: event.target.value})
                                  if (event.target.value.toUpperCase() === "CUSTOM") {
                                    setShowAuth(true)
                                  } else {
                                    setShowAuth(false)
                                  }
                                }}
                              >
                                <option key="" value="">Marca</option>
                                {supportedPrinters.map((object) => {
                                  const selected = (PRINTER.brand === object.brand)
                                  return <option selected={selected} key={object.brand} value={object.brand}>{object.brand}</option>
                                })}
                              </select>
                            </div>
                            <div className="w-1/2">
                              <select 
                              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              onChange={(event) => setPrinter({...PRINTER, model: event.target.value})}
                              >
                                <option key="" value="">Modello</option>
                                {supportedPrinters.map((object) => {
                                  if (object.brand === PRINTER.brand) {
                                    return object.models.map((model) => {
                                      const selected = (PRINTER.brand+"-"+PRINTER.model === object.brand+"-"+model)

                                    return <option selected={selected} key={PRINTER.brand+"-"+model} value={model}>{model}</option>
                                    })
                                  } else return null
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* auth */}
                      <div className={(showAuth)? 'flex flex-row space-x-2': 'hidden flex-row space-x-2'}>
                        <div className='w-1/3 text-center uppercase'>
                          <label
                            className='block uppercase text-gray-700 text-xs font-bold mb-2'
                            htmlFor='grid-password'
                          >
                            Username
                          </label>
                          <input
                            type='text'
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                            placeholder='Username'
                            value={PRINTER.printerAuth.username}
                            onChange={e => {
                              setPrinter({...PRINTER, printerAuth: {...PRINTER.printerAuth, username: e.target.value}})
                            }}
                          />
                        </div>
                        <div className='w-1/3 text-center uppercase'>
                          <label
                            className='block uppercase text-gray-700 text-xs font-bold mb-2'
                            htmlFor='grid-password'
                          >
                            Password
                          </label>
                          <input
                            type='text'
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                            placeholder='Password'
                            value={PRINTER.printerAuth.password}
                            onChange={e => {
                              setPrinter({...PRINTER, printerAuth: {...PRINTER.printerAuth, password: e.target.value}})
                            }}
                          />
                        </div>
                      </div>
                      {/* Action */}
                      <div className='flex flex-row'>
                        <div className="flex flex-col w-1/6 justify-center items-center">
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={()=>{
                              const printer = {
                                name: PRINTER.name,
                                brand: PRINTER.brand,
                                model: PRINTER.model,
                                ip: PRINTER.ip,
                                url: PRINTER.url,
                                port: PRINTER.port,
                                automaticClousure: PRINTER.automaticClousure,
                                isSSL: (PRINTER.isSSL)? true: false,
                                isFiscal: (PRINTER.isFiscal)? true: false,
                                printerAuth: {
                                  username: PRINTER.printerAuth.username,
                                  password: PRINTER.printerAuth.password
                                }
                              }
                              console.log('PRINTER', printer)
                              const variables : {
                                deviceId: string,
                                printerId: string,
                                printer: IPrinterInput
                              } = {
                                deviceId: DEVICE._id,
                                printerId: PRINTER._id,
                                printer
                              }
                                editPrinter({variables})
                              }}>
                              Modifica
                            </button>
                        </div>
                      </div>
                    </div>

                      

                    {/* LOGS */}
                  <div><LogsTable logs={PRINTER.logs} /></div>
                  {/* ACTIONS */}
                  </div>
                    <div className="flex flex-row w-full">
                      <div className="flex w-1/2 justify-center">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={()=>{
                          history.push(`/device/${_id}`)
                          }
                        }>
                          Torna a dispositivo
                        </button>
                      </div>
                      <div className="flex w-1/2 justify-center">
                        <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={()=>{
                          setIsOpen(true)
                          }}>
                          Rimuovi Stampante
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* COMMANDS */}
                  <div className="flex flex-row w-full pt-4">
                    <div className="w-3/4">
                      <div>
                        <div className="flex flex-row text-justify">
                          <div
                            className={
                              'font-semibold text-lg w-1/2 text-gray-800'
                            }
                          >
                            Commando Recipt JSON
                          </div>
                          <div
                            className={
                              'font-semibold text-lg  w-1/2 text-gray-800 text-right'
                            }
                          >
                            {jsonCorrect}
                          </div>
                        </div>
                      </div>
                      <div className="pt-2">
                        <textarea
                          className='px-3 py-3 h-96 overflow-visible resize-y placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150' value={jsonCommand}
                          onChange={
                            e => {
                              setJsonCommand(e.target.value)
                              setJsonCorrect(checkJSON(e.target.value))
                            }
                          }
                          ></textarea>
                      </div>
                      <div className="pt-2 text-center">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={(event)=>{
                                handleSendCommand(jsonCommand)
                              }}>
                              Invia Comando
                        </button>
                        <div>
                          Risultato:
                          {jsonCommandResult}
                        </div>
                      </div>
                    </div>
                    {examples}
                  </div>
                  {
                    isRoot && (
                      <div className="flex flex-row w-full pt-4">
                        <div className="w-full">
                          <div>
                            <div className="flex flex-row text-justify">
                              <div
                                className={
                                  'font-semibold text-lg w-1/2 text-gray-800'
                                }
                              >
                                Commando diretto Sync a stampante (solitamente XML)
                              </div>
                            </div>
                          </div>
                          <div className="pt-2">
                            <textarea
                              className='px-3 py-3 h-96 overflow-visible resize-y placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150' value={directCommand}
                              onChange={
                                e => {
                                  setDirectCommand(e.target.value)
                                }
                              }
                              ></textarea>
                          </div>
                          <div className="pt-2 text-center">
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={(event)=>{
                                    handleSendDirectCommand(directCommand)
                                  }}>
                                  Invia Comando
                            </button>
                            <div>
                              Risultato:
                              {directCommandResult}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  )
}

