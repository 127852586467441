const screenHeight = "100vh"
const screenWidth = "100vw"

type Colors =
  | "mowi"
  | "green"
  | "greenLight"
  | "greenDark"
  | "cyan"
  | "blue"
  | "blueLight"
  | "blueDark"
  | "yellow"
  | "orange"
  | "violet"
  | "red"
  | "redLight"
  | "grey"
  | "greyLight"
  | "greyDark"
  | "light"
  | "dark"
  | "white"
  | "black"
  | "primary"
  | "secondary"
  | "info"
  | "success"
  | "error"
  | "warning"
  | "darkOpacity"
  | "lightOpacity"
  | "default"

let colors: { [Color in Colors]: string } = {
  mowi: "#268FCD",
  green: "#599B4E",
  greenLight: "#8CC37D",
  greenDark: "#649A5C",
  cyan: "#1875B2",
  blue: "#008BAC",
  blueLight: "#79B3D3",
  blueDark: "#345087",
  yellow: "#F4C84D",
  orange: "#B29C6D",
  violet: "#5A4282",
  red: "#bc3535",
  redLight: "#d15c5c",
  grey: "#5A5A5A",
  greyLight: "#989898",
  greyDark: "#353535",
  light: "#EAEAEA",
  dark: "#101111",
  white: "#FFFFFF",
  black: "#000000",
  primary: "#5A4282",
  secondary: "#8CC37D",
  info: "#008BAC",
  success: "#599B4E",
  error: "#D15D5D",
  warning: "#B29C6D",
  darkOpacity: "rgba(16,17,17,0.8)",
  lightOpacity: "rgba(255,255,255,0.8)",
  default: "#525251",
}

const vars = {
  fontSizes: {
    xs: 6,
    s: 8,
    m: 10,
    l: 14,
    xl: 20,
    xxl: 28,
  },
  colors,
  screen: {
    width: screenWidth,
    height: screenHeight,
  },
  base: {
    margin: 5,
    radius: 10,
    border: 0,
    textColor: colors.white,
    textFont: "kanit-regular",
    textFontBold: "kanit-bold",
    iconFont: "mowi-icons",
    bkgColor: colors.dark,
  },
}

// Texts

const textDefault = {
  color: vars.base.textColor,
  fontFamily: vars.base.textFont,
  fontSize: vars.fontSizes.l,
}

const text = {
  xs: { ...textDefault, fontSize: vars.fontSizes.xs },
  s: { ...textDefault, fontSize: vars.fontSizes.s },
  m: { ...textDefault, fontSize: vars.fontSizes.m },
  l: { ...textDefault, fontSize: vars.fontSizes.l },
  xl: { ...textDefault, fontSize: vars.fontSizes.xl },
  bold: {
    fontFamily: "kanit-bold",
  },
  blockCenter: {
    width: "100%",
    textAlign: "center",
  },
}

// Generals

const general = {
  fullAbsulute: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: screenWidth,
    height: screenHeight,
  },
  fullWidth: {
    width: "100%",
    maxWidth: "100%",
  },
  pageContainer: {
    width: "100%",
    height: "100%",
    maxWidth: 1000,
    borderColor: colors.greyDark,
    alignSelf: "center",
  },
  pageContainerMin: {
    width: "100%",
    height: "100%",
    maxWidth: 600,
    alignSelf: "center",
  },
}

const helpers = {
  checkColor: (color: Colors) => {
    let resColor: Colors = vars.colors.default as Colors
    let cColor: Colors = color
    return color && colors[cColor] ? color : resColor
  },
}

const theme = {
  vars,
  general,
  text,
  helpers,
}

export { theme }
export type { Colors }
