import {useState, useEffect} from 'react'
import { useQuery } from "@apollo/client";
import {  useHistory } from "react-router";
import { GET_ALL_DEVICES } from '../../graphql/queries';
import { Link } from 'react-router-dom';
import { IDevice } from '../../graphql/types';

const obscurate = (s: string) => '*'.repeat(Math.min(12, s.length)) + s.slice(12);

const cutString = ({s,length}: {s?: string, length: number}) => {
  if (!s) return ''
  if (s.length < length) {
    return s
  } else {
    return s.substr(0, length-1) + '...'
  }
}
function DeviceTableRow ({ color, device }:{color: any, device:IDevice}) {
  let history = useHistory();
  const redirect : any = (device: any) => {
    history.push(`/device/${device._id}`)
  }
  
  const deviceStatus = device.socket ? (
    <>
      <i className='fas fa-circle text-green-500 mr-2'></i> online
    </>
  ) : (
    <>
      <i className='fas fa-circle text-red-500 mr-2'></i> offline
    </>
  )
  return (
    <tr className='group cursor-pointer' key={device._id}  onClick={() => redirect(device)}>
      <td className='group-hover:bg-gray-500 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4'>
        {device._id}
      </td>
      <td className='group-hover:bg-gray-500 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4'>
        {device.name}
      </td>
      <td className='group-hover:bg-gray-500 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4'>
        {cutString({s: device?.hardware?.ips, length: 32})}
      </td>
      <td className='group-hover:bg-gray-500 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4'>
        {obscurate(device.serial)}
      </td>
      <td className='group-hover:bg-gray-500 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4'>
        {device.printers ? device.printers.length : 0}
      </td>
      <td className='group-hover:bg-gray-500 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4'>
        {deviceStatus}
      </td>
      {/* <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-right'>
        <DeviceTableDropdown deviceId={device._id} />
      </td> */}
    </tr>
  )
}


export default function DevicesTable () {
  const { loading, error, data, startPolling, stopPolling } = useQuery(GET_ALL_DEVICES,{fetchPolicy:"cache-and-network", onCompleted: ()=>{console.log('ALL_DEVICES_QUERY Completed')}})
  useEffect(() => {
    console.log('startPooling')
    startPolling(5000)
  return () => {
    console.log('stopPooling')
   stopPolling()
  }
}, [startPolling, stopPolling])

  const [devices, setDevices] = useState([])
  useEffect(() => {
    if (data){
      setDevices(data.devices)
    }
  }, [data])
  if (error) {
    console.log(`Error: ${error}`)
    return <div>Error loading players.</div>
  }
  if (loading) return <div>Loading</div>
  // let { devices }: {devices : any} = data
  console.log(`devices: ${JSON.stringify(devices)}`)

  const color = 'light'
  const deviceRows = devices.map((device: any) => {
    return DeviceTableRow({ color, device })
  })
  // const selectDevice = (device: any) => {
  //   client.writeQuery({
  //     query: GET_LOCAL_SELECTED_DEVICE,
  //     data: {selectedDevice: device}
  //   })
  // }


  return (
    <>
      <div
        className={
          'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white'
        }
      >
        <div className='rounded-t mb-0 px-4 py-3 border-0'>
          <div className='flex flex-wrap items-center'>
            <div className='relative w-full px-4 max-w-full flex-grow flex-1'>
              <div
                className={
                  'font-semibold text-lg text-gray-800'
                }
              >
                Dispositivi
              </div>
            </div>
            <div>
              <Link to='/devices/register'>
                <i className='fas text-lg fa-plus-circle cursor-pointer'></i>
              </Link>
            </div>
          </div>
        </div>
        <div className='block w-full overflow-x-auto'>
          {/* Projects table */}
          <table className='items-center w-full bg-transparent border-collapse'>
            <thead>
              <tr>
              <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200'
                  }
                >
                  ID
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200'
                  }
                >
                  Nome
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200'
                  }
                >
                  IP
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200'
                  }
                >
                  Seriale
                </th>
                <th className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200'
                    
                  }>
                    Numero stampanti
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200'
                  }
                >
                  Stato
                </th>
                {/* <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200'
                  }
                ></th> */}
              </tr>
            </thead>
            <tbody>
                {deviceRows}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}