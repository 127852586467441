import React, { useContext, createContext, useReducer, Dispatch } from "react"
import { DB_AUTH } from "../stores/db/auth"
import { initialState, AuthReducer, State, Action} from "./reducer"
import { ENV, LOG } from "../../config"
import jwtDecode from "jwt-decode"

const AuthStateContext = createContext<Partial<State>>({})
const AuthDispatchContext = createContext<Dispatch<Action>>(() => null)
const log = LOG.extend("auth")

export function AuthProvider({ children }: { children: any }) {
  const [user, dispatch] = useReducer(AuthReducer, initialState)
  return (
    <AuthStateContext.Provider value={user}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

export function useAuthState() {
  const context = useContext(AuthStateContext)
  if (context === null) {
    throw new Error("useAuthState must be used within a AuthProvider")
  }

  return context
}

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext)
  if (context === null) {
    throw new Error("useAuthDispatch must be used within a AuthProvider")
  }

  return context
}

type apolloAuthResponse = {
  data: {
    loginUser: {
      accessToken: string,
      refreshToken: string,
      info: string,
      user: {
        id: string
        email: string
        company: {
          name: string
          location: {
            city: string,
            postCode: string,
            nation: string,
            street: string
          }
          vat: string
        }
        auth: {
          createdAt: Date
          isActive: boolean
          isEmailVerified: boolean
          lastLogin: Date
          permissions: string[]
        }
      }
    }
  }
  errors: string[]
}

type apolloImpersonateResponse = {
  data: {
    impersonateUser: {
      accessToken: string,
      refreshToken: string,
      info: string,
      user: {
        id: string
        email: string
        company: {
          name: string
          location: {
            city: string,
            postCode: string,
            nation: string,
            street: string
          }
          vat: string
        }
        auth: {
          createdAt: Date
          isActive: boolean
          isEmailVerified: boolean
          lastLogin: Date
          permissions: string[]
        }
      }
    }
  }
  errors: string[]
}

// Login user con email e password
export const loginUser = async (
  dispatch: React.Dispatch<Action>,
  {email, password }: {email: string, password: string}
) => {
  if (!ENV.auth.uri) {
    log.error("ENV.auth.uri must be set to loginUser")
    throw new Error("ENV.auth.uri must be set to loginUser")
  }

  if (!email || !password) {
    log.debug("Login Request Faild, NO EMAIL OR PASSWORD")
    dispatch({
      type: "LOGIN_ERROR",
      error: "Campi email e password obbligatori",
    })
    return false
  }

  // if (password.length < 8) {
  //   log.debug("Login Request Faild, Password small")
  //   dispatch({
  //     type: "LOGIN_ERROR",
  //     error: "Password troppo corta, utilizza almeno 8 caratteri",
  //   })
  //   return false
  // }

  try {
    dispatch({ type: "REQUEST_LOGIN" })
    const query = JSON.stringify({
      query: `mutation {
        loginUser(
            email: "${email}",
            password: "${password}",
          ) {
            info
            accessToken
            refreshToken
            user {
              id
              email
              company {
                name
                location {
                  city
                  postCode
                  nation
                  street
                }
                vat
              }
              auth {
                createdAt
                isActive
                isEmailVerified
                lastLogin
                permissions
              }
            }
          }
        }
      `,
    })

    const response = await fetch(ENV.auth.uri, {
      headers: { "content-type": "application/json" },
      method: "POST",
      body: query,
    })

    const loginResponse = await handleLoginResponse(
      response,
      "loginUser",
      dispatch
    )
    return loginResponse
  } catch (error) {
    log.error(`loginUser ERROR: ${error}`)
    dispatch({ type: "LOGIN_ERROR", error: "Errore generale, riprovare piu' tardi" })
  }
}

export const impersonateUser = async (
  dispatch: React.Dispatch<Action>,
  {id }: {id: string}
) => {
  if (!ENV.auth.uri) {
    log.error("ENV.auth.uri must be set to loginUser")
    throw new Error("ENV.auth.uri must be set to loginUser")
  }

  if (!id) {
    log.debug("Impersonate Request Faild, NO ID")
    dispatch({
      type: "LOGIN_ERROR",
      error: "ID utente obbligatorio",
    })
    return false
  }

  try {
    dispatch({ type: "REQUEST_LOGIN" })
    const query = JSON.stringify({
      query: `mutation {
        impersonateUser(
            id: "${id}"
          ) {
            info
            accessToken
            refreshToken
            user {
              id
              email
              company {
                name
                location {
                  city
                  postCode
                  nation
                  street
                }
                vat
              }
              auth {
                createdAt
                isActive
                isEmailVerified
                lastLogin
                permissions
              }
            }
          }
        }
      `,
    })

    const accessToken = DB_AUTH.get().accessToken

    const response = await fetch(ENV.auth.uri, {
      headers: { 
        "content-type": "application/json",
        authorization: accessToken || "",
      },
      method: "POST",
      body: query,
    })

    const loginResponse = await handleImpersonateResponse(
      response,
      "impersonateUser",
      dispatch
    )
    return loginResponse
  } catch (error) {
    log.error(`impersonateUser ERROR: ${error}`)
    dispatch({ type: "LOGIN_ERROR", error: "Errore generale, riprovare piu' tardi" })
  }
}

export const changePassword = async ({email, code, password}:{email: string, code: string, password: string}) => {
  const query = JSON.stringify({
    query: `mutation {
      changePassword(
        email: "${email}"
        password: "${password}"
        changePasswordCode: "${code}"
      )
    }`})
  return await fetch(ENV.auth.uri, {
    headers: { "content-type": "application/json" },
    method: "POST",
    body: query,
  })
}

export const sendPasswordReset = async ({email}:{email: string}) => {
  const query = JSON.stringify({
    query: `mutation {
      sendChangePasswordEmail(
        email: "${email}"
      )
    }`})
  return await fetch(ENV.auth.uri, {
    headers: { "content-type": "application/json" },
    method: "POST",
    body: query,
  })
}

// Logout user e dispositivo
export const logout = async () => {
  log.warn("LOGOUT ACTION")
  // log.debug("LOGOUT: clear cache")
  // await getApolloClient().clearStore()
  log.debug("LOGOUT: clear auth")
  DB_AUTH.clear()
  // log.debug("LOGOUT: clear rt")
  // log.debug("LOGOUT: clear user state")
  // dispatch({ type: "LOGOUT" })

  return true
}

const handleLoginResponse = async (
  response: Response,
  mutation: "loginUser",
  dispatch: React.Dispatch<Action>
) => {
  const loginResponse = await response.json() as apolloAuthResponse

  if (loginResponse.errors) {
    dispatch({ type: "LOGIN_ERROR", error: "Errore nel processo di autenticazione, riprovare piu' tardi." })
    log.error("Error from server: " + loginResponse.errors[0])
    return false
  }

  if (!loginResponse || !loginResponse.data) {
    dispatch({ type: "LOGIN_ERROR", error: "Errore nel processo di autenticazione, riprovare piu' tardi." })
    log.error("Error from server: " + loginResponse.errors[0])
    return false
  }

  if (
    !loginResponse.data[mutation] ||
    !loginResponse.data[mutation].accessToken ||
    !loginResponse.data[mutation].refreshToken ||
    !loginResponse.data[mutation].user
  ) {
    log.debug("Login Request Faild, NO TOKENS")
    dispatch({ type: "LOGIN_ERROR", error: "Username o password errati" })
    return false
  }

  const tokens = {
    accessToken: loginResponse.data[mutation].accessToken,
    refreshToken: loginResponse.data[mutation].refreshToken,
  }

  const user = loginResponse.data[mutation].user
  if (!user) {
    log.debug("Login Request Faild, NO user in response")
    dispatch({ type: "LOGIN_ERROR", error: "Errore nel processo di autenticazione, riprovare piu' tardi." })
    return false
  }

  dispatch({
    type: "LOGIN_SUCCESS",
    payload: {
      user,
      accessToken: tokens.accessToken,
      refreshToken: tokens.refreshToken,
    },
  })

  DB_AUTH.set({
    user: user,
    accessToken: tokens.accessToken,
    refreshToken: tokens.refreshToken,
  })

  return true
}

const handleImpersonateResponse = async (
  response: Response,
  mutation: "impersonateUser",
  dispatch: React.Dispatch<Action>
) => {
  const loginResponse = await response.json() as apolloImpersonateResponse

  if (loginResponse.errors) {
    dispatch({ type: "LOGIN_ERROR", error: "Errore nel processo di autenticazione, riprovare piu' tardi." })
    log.error("Error from server: " + loginResponse.errors[0])
    return false
  }

  if (!loginResponse || !loginResponse.data) {
    dispatch({ type: "LOGIN_ERROR", error: "Errore nel processo di autenticazione, riprovare piu' tardi." })
    log.error("Error from server: " + loginResponse.errors[0])
    return false
  }

  if (
    !loginResponse.data[mutation] ||
    !loginResponse.data[mutation].accessToken ||
    !loginResponse.data[mutation].refreshToken ||
    !loginResponse.data[mutation].user
  ) {
    log.debug("Login Request Faild, NO TOKENS")
    dispatch({ type: "LOGIN_ERROR", error: "Username o password errati" })
    return false
  }

  const tokens = {
    accessToken: loginResponse.data[mutation].accessToken,
    refreshToken: loginResponse.data[mutation].refreshToken,
  }

  const user = loginResponse.data[mutation].user
  if (!user) {
    log.debug("Login Request Faild, NO user in response")
    dispatch({ type: "LOGIN_ERROR", error: "Errore nel processo di autenticazione, riprovare piu' tardi." })
    return false
  }

  dispatch({
    type: "LOGIN_SUCCESS",
    payload: {
      user,
      accessToken: tokens.accessToken,
      refreshToken: tokens.refreshToken,
    },
  })

  DB_AUTH.set({
    user: user,
    accessToken: tokens.accessToken,
    refreshToken: tokens.refreshToken,
  })

  return true
}

type FetchNewAccessToken = (
  refreshToken: string
) => Promise<{ accessToken: string; refreshToken: string } | undefined>;


export const fetchNewAccessToken: FetchNewAccessToken = async (refreshToken) => {
  console.log('Fetch new accessToken')
  if (!ENV.auth.uri) {
    throw new Error('ENV.auth.uri must be set to use refresh token link');
  }
  try {
    const query = JSON.stringify({
      query: `mutation {
        refreshToken(
            refreshToken: "${refreshToken}",
          ) { 
            accessToken
            refreshToken
          }
      }
      `,
    });

    const response = await fetch(ENV.auth.uri, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: query,
    });

    const refreshResponse = await response.json();

    if (
      !refreshResponse ||
      !refreshResponse.data ||
      !refreshResponse.data.refreshToken ||
      !refreshResponse.data.refreshToken.accessToken
    ) {
      return undefined;
    }

    return {
      accessToken: refreshResponse.data.refreshToken.accessToken,
      refreshToken: refreshResponse.data.refreshToken.refreshToken,
    };
  } catch ({ message }) {
    log.error(`Fetch New Access Token ERROR: ${message}`);
    return undefined;
  }
};

export const isTokenValid = (token: string | null | undefined): boolean => {
  if (!token) {
    return false;
  }
  const decodedToken: { [key: string]: any } | undefined = decodeToken(token);
  if (!decodedToken) {
    return false;
  }
  const now = new Date();
  const isValid =  now.getTime() < decodedToken.exp * 1000;
  console.log(`token is valid?`,isValid)
  return isValid
};

export const decodeToken = (token: string) => {
  if (!token) {
    return undefined;
  }

  let decodedToken: { [key: string]: any };

  try {
    decodedToken = jwtDecode<{ [key: string]: any }>(token);
  } catch ({ message }) {
    log.error(`Decode Access Token ERROR: ${message}`);
    return undefined;
  }

  if (!decodedToken) {
    log.warn('Token decode error (=null) | ' + token);
    return undefined;
  }

  return decodedToken;
};