import { sendPasswordReset } from '../../libs/auths/auth';
import { useFormFields } from '../../libs/hooks/forms';

export function AskResetPassword (props: any) {
    const [inputs, handleInputChange] = useFormFields<{
      email: string;
    }>({
      email: '',
    }); 
    const handleResetPassword = async  (event: any) => {
      event.preventDefault()
      try {
        let res = await sendPasswordReset({...inputs})
        if (res) {
          props.history.push('/auth/login')
        }
      } catch (error) {
        console.log('handleResetPassword error', error)
      }
    }
  return (
    <>
  <div className="container max-w-full mx-auto py-24 px-6">
    <div className="font-sans">
      <div className="max-w-sm mx-auto px-6">
        <div className="relative flex flex-wrap">
          <div className="w-full relative">
            <div className="mt-6">
              <div className="text-center font-semibold text-black">
                Stampante Fiscale Reset Password
              </div>
              {/* <div>{errorMessage}</div> */}
              <form className="mt-8">
                <div className="mx-auto max-w-lg">
                  <div className="py-2">
                    <span className="px-1 text-sm text-gray-600">Email</span>
                    <input 
                      className="text-md block px-3 py-2  rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                      id="email"
                      type='email'
                      placeholder='Email'
                      autoComplete="username"
                      required={true}
                      onChange={handleInputChange}
                    />
                  </div>
                  <button 
                    className="mt-3 text-lg font-semibold bg-gray-800 w-full text-white rounded-lg px-6 py-3 block shadow-xl hover:text-white hover:bg-black"
                    type='button'
                    onClick={handleResetPassword}
                  >
                    Invia Email Reset Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </>
  )
}
