// import React, { useState, useReducer } from "react"
import { Auth, DB_AUTH } from "../stores/db/auth"

let user: Auth["user"] = DB_AUTH.get() ? DB_AUTH.get().user : {}
let accessToken = DB_AUTH.get() ? DB_AUTH.get().accessToken : ""
let refreshToken = DB_AUTH.get() ? DB_AUTH.get().refreshToken : ""

export const initialState: State = {
  user: user,
  accessToken: "" || accessToken,
  refreshToken: "" || refreshToken,
  loading: false,
  errorMessage: null,
}

export type State = {
  user: Auth["user"]
  accessToken: string
  refreshToken: string
  loading: boolean
  errorMessage: string | null
}

export type Action =
  | {
      type: "REQUEST_LOGIN"
    }
  | {
      type: "LOGIN_SUCCESS"
      payload: {
        user: Auth["user"]
        accessToken: string
        refreshToken: string
      }
    }
  | {
      type: "TOKEN_REFRESH_SUCCESS"
      payload: {
        accessToken: string
        refreshToken: string
      }
    }
  | {
      type: "LOGOUT"
    }
  | {
      type: "LOGIN_ERROR"
      error: string
    }

export const AuthReducer = (initialState: State, action: Action): State => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true,
      }
    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        user: action.payload.user,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        loading: false,
        errorMessage: null,
      }
    case "TOKEN_REFRESH_SUCCESS":
      return {
        ...initialState,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        loading: false,
        errorMessage: null,
      }
    case "LOGOUT":
      return {
        ...initialState,
        user: {},
        accessToken: "",
        refreshToken: "",
      }

    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      }

    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}
