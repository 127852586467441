import { gql } from "@apollo/client"

export const GET_ALL_USERS = gql`
  query allUsers {
    allUsers {
      id
      email
      company {
          name
        }
      }
    }
`

export const GET_ALL_DEVICES = gql`
  query allDevices {
    devices {
      _id
      name
      serial
      socket
      deviceApiAuthCode
      hardware {
        ips
        macs
        hostname
        swVersion
      }
      printers {
        _id
        name
        onlineStatus
        brand
        model
        ip
        logs {
          _id
          description
          isCompleted
          createdAt
          lastUpdate
          info {
            level
            description
          }
        }
      }
    }
  }
`

export const USERME = gql`
  query userMe {
    userMe {
      id
      email
      userApiAuthCode
      company {
        name
        location {
          city
          postCode
          nation
          street
        }
        vat
      }
    }
  }
`

export const GET_ONE_DEVICE = gql`
  query device($id: ID!) {
    Device(id: $id) {
      _id
      name
      serial
      socket
      hardware {
        ips
        macs
        hostname
        swVersion
      }
      deviceApiAuthCode
      printers {
        _id
        name
        onlineStatus
        brand
        model
        ip
        port
        url
        isSSL
        isFiscal
        automaticClousure
        logs {
          _id
          description
          isCompleted
          createdAt
          lastUpdate
          info {
            level
            description
          }
        }
        printerAuth {
          username
          password
        }
      }
      logs {
        _id
        description
        isCompleted
        createdAt
        lastUpdate
        info {
          level
          description
        }
      }
    }
  }
`

export const UPDATE_ONE_DEVICE = gql`
  mutation device($deviceId: String!, $inputData: UpdateDeviceInput!) {
    deviceUpdate(deviceId: $deviceId, inputData: $inputData) {
      _id
      user
      name
    }
  }
`

export const GET_LOCAL_SELECTED_DEVICE = gql`
  {
    selectedDevice @client
  }
`

export const UNREGISTER_DEVICE = gql`
  mutation unregisterDevice($deviceId: ID!) {
    deviceUnregister(deviceId: $deviceId) {
      _id
      name
      user
    }
  }
`

export const REGISTER_DEVICE = gql`
  mutation registerDevice($serial: String!) {
    deviceRegister(serial: $serial) {
      _id
    }
  }
`

export const ADD_PRINTER_TO_DEVICE = gql`
  mutation devicePrinterAdd($deviceId: ID!, $printer: PrinterInput!) {
    devicePrinterAdd(deviceId: $deviceId, printer: $printer) {
      _id
    }
  }
`

export const REMOVE_PRINTER_FROM_DEVICE = gql`
  mutation devicePrinterRemove($deviceId: ID!, $printerId: ID!) {
    devicePrinterRemove(deviceId: $deviceId, printerId: $printerId) {
      _id
      printers {
        _id
      }
    }
  }
`
export const EDIT_PRINTER_ON_DEVICE = gql`
  mutation devicePrinterEdit(
    $deviceId: ID!
    $printerId: ID!
    $printer: PrinterInput!
  ) {
    devicePrinterEdit(
      deviceId: $deviceId
      printerId: $printerId
      printer: $printer
    ) {
      _id
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $email: String!
    $password: String!
    $changePasswordCode: String!
  ) {
    changePassword(
      email: $email
      password: $password
      changePasswordCode: $changePasswordCode
    )
  }
`
