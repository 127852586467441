import React from 'react';
import {AppRoutes} from './components/routes/AppRoute'

import {
  HashRouter,
  Switch,
} from "react-router-dom";
import './App.css';
import {routes} from './config/routes';
import { AuthProvider } from './libs/auths/auth'
import { ApolloProvider } from '@apollo/client';
import {useApollo} from './libs/apollo/client'

function App() {
  const apolloClient = useApollo(null)
  return (
    <AuthProvider>
      <ApolloProvider client={apolloClient}>
        <HashRouter>
          <Switch>
            {routes.map((route) => (
              <AppRoutes
                key={route.path}
                path={route.path}
                component={route.component}
                isPrivate={route.isPrivate}
              />
            ))}
          </Switch>
        </HashRouter>
      </ApolloProvider>
    </AuthProvider>
  );
}

export default App;
