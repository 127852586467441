import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router';
import { USERME } from '../../graphql/queries'
import { IUser } from '../../graphql/types';
import { DB_AUTH } from '../../libs/stores/db/auth';


// const checkVat = (pIva: string | undefined) => {
//   if (!pIva) return false
//   return (pIva.length === 16 || pIva.length === 11)
// }

export function UserProfile (props: any) {
  const history = useHistory();
  const permissions = DB_AUTH.get().user?.auth?.permissions || []
  const { 
    loading, 
    error, 
    data
  } : {
    loading?: any,
    error?: any,
    data?: {
      userMe: IUser
    }
  }  = useQuery(USERME,{fetchPolicy:"cache-and-network", onCompleted: ()=>{console.log('USERME Completed', data?.userMe)}})
  // const [user, setUser]:any = useState(null)
  // useEffect(() => {
  //   if (data){
  //     setUser(data.userMe)
  //   }
  // }, [data])

  if (error) {
    console.log(`Error: ${error}`)
    return <div>Error loading players.</div>
  }

  if (loading) return <div>Loading!!!</div>
  let user : IUser
  if (data && data.userMe) {
    user = data.userMe
  } else {
    return <div>User Not found</div>
  }
  console.log('user', user)
  return (
    <>
      <div className='flex flex-wrap w-1/2'>
        <div className='mb-12 px-4'>
          <div
            className={
              'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded '
            }
          >
            <div className='rounded-t mb-0 px-4 py-3 border-0'>
              <div className='flex flex-wrap items-center'>
                <div className='flex flex-row justify-between relative w-full px-4 max-w-full flex-grow flex-1'>
                  <div
                    className={
                      'font-semibold text-lg content-center w-full text-center uppercase' 
                    }
                  >
                    Profilo utente { permissions.includes('root') ? '(ROOT)' : ''}
                  </div>
                </div>
                <div className='block w-full overflow-x-auto pt-4'>
                    <div className='block'>
                      <div className='relative w-full mb-3'>
                        <label
                          className='block uppercase text-gray-700 text-xs font-bold mb-2'
                          htmlFor='grid-password'
                        >
                          Email
                        </label>
                        <div>{user.email}</div>
                        {/* <input
                          type='text'
                          disabled
                          className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                          placeholder='email'
                          value={email}
                          onChange={e => {setEmail(e.target.value)}}
                        /> */}
                      </div>
                    </div>
                    <div className='block'>
                      <div className='relative w-full mb-3'>
                        <label
                          className='block uppercase text-gray-700 text-xs font-bold mb-2'
                          htmlFor='grid-password'
                        >
                          User API Auth Code
                        </label>
                        <div>{user.userApiAuthCode}</div>
                        {/* <input
                          type='text'
                          disabled
                          className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                          placeholder='email'
                          value={email}
                          onChange={e => {setEmail(e.target.value)}}
                        /> */}
                      </div>
                    </div>
                    <div className='block'>
                    <div className='relative w-full mb-3'>
                    <label
                          className='block uppercase text-gray-700 text-xs font-bold mb-2'
                          htmlFor='grid-password'
                        >
                          Denominazione Azienda
                        </label>
                        <div>{user.company.name}</div>
                        {/* <input
                          type='text'
                          className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                          placeholder='Nome'
                          value={company.name}
                          onChange={e => {company.name = e.target.value;setCompany({...company})}}
                        /> */}
                      </div>
                      <div className="relative w-full mb-3">
                      <label
                          className='block uppercase text-gray-700 text-xs font-bold mb-2'
                          htmlFor='grid-password'
                        >
                          Partita IVA
                        </label>
                        <div>{user.company.vat}</div>
                        {/* <input
                          type='text'
                          className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                          placeholder='Nome'
                          value={company.vat}
                          onChange={e => {company.vat = e.target.value;setCompany({...company})}}
                        /> */}
                      </div>
                      <div>
                        <label
                          className='block uppercase text-gray-700 text-xs font-bold mb-2'
                          htmlFor='grid-password'
                        >
                          Indirizzo
                        </label>
                        <div className='relative w-full mb-3'>
                          <div>
                            <div>{user.company.location.street}</div>
                            <div>{user.company.location.postCode}</div>
                            <div>{user.company.location.city}</div>
                            <div>{user.company.location.nation}</div>
                            {/* <input
                              type='text'
                              className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                              placeholder='Nome'
                              value={company.location.city}
                              onChange={e => {company.location.city = e.target.value;setCompany({...company})}}
                            /> */}
                          </div>
                          {/* <div className="pt-2">
                            <label
                              className='block uppercase text-gray-700 text-xs font-bold mb-2'
                              htmlFor='grid-password'
                            >
                              Via e numero
                            </label>
                            <input
                              type='text'
                              className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                              placeholder='Nome'
                              value={company.location.street}
                              onChange={e => {company.location.street = e.target.value;setCompany({...company})}}
                            />
                          </div>
                          <div className="pt-2">
                            <label
                              className='block uppercase text-gray-700 text-xs font-bold mb-2'
                              htmlFor='grid-password'
                            >
                              CAP
                            </label>
                            <input
                              type='text'
                              className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                              placeholder='Nome'
                              value={company.location.postCode}
                              onChange={e => {company.location.postCode = e.target.value;setCompany({...company})}}
                            />
                            </div>*/}
                          </div> 
                        </div>
                    </div>
                  
                  <div className="block flex justify-center">
                    {/* <div className="flex w-1/2 justify-center">
                      <button disabled={(checkIfComplete()!==null)} className="disabled:opacity-50 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={()=>{
                        console.log('eccomi')
                        }}>
                        Modifica
                      </button>
                    </div> */}
                    <div className="flex ">
                      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={()=>{
                        history.push(`/`)
                        }
                      }>
                        Dashboard
                      </button>
                    </div>
                    {/* <div  className="flex-1"></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

