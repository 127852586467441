import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useHistory, useParams } from 'react-router';
import {UPDATE_ONE_DEVICE, GET_ONE_DEVICE, UNREGISTER_DEVICE} from '../../graphql/queries'
import PrintersTable from './PrintersTable'
import LogsTable from './LogsTable'
import Modal from 'react-modal';

const obscurate = (s: string) => '*'.repeat(Math.min(12, s.length)) + s.slice(12);

const modalStyle = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

Modal.setAppElement('#root');

export default function UpdateDevice (props: any) {
  const history = useHistory();
  const [name, setName] = useState('')
  const [modalIsOpen,setIsOpen] = useState(false);  
  const [editDevice] = useMutation(UPDATE_ONE_DEVICE,{onCompleted: (res) => {
    console.log('Updated done',res)
  }, onError: (err) => console.log(`Error in Updating: ${err}`)});
  const [
    unregisterDevice,
  ] = useMutation(UNREGISTER_DEVICE, {onCompleted: ()=> {console.log('Unregistered'); history.push('/devices')}, onError: (err) => console.log(`Error in unregistering: ${err}`)});


  let { _id }: any = useParams();

  let { loading: queryLoading, error: queryError, data, startPolling, stopPolling } = useQuery(GET_ONE_DEVICE, {
    fetchPolicy:"cache-and-network",
    variables: { id: _id },
    onCompleted: (data) => {
      if (data.Device.name) setName(data.Device.name)
      console.log('GET_ONE_DEVICE completed', data.Device.name)
    },
  })

  useEffect(() => {
    console.log('startPooling')
    startPolling(5000)
  return () => {
    console.log('stopPooling')
   stopPolling()
  }
}, [startPolling, stopPolling])

  if (queryError) {
    console.log(`Error: ${queryError}`)
    return <div>Error loading the device..</div>
  }
  if (queryLoading) return <div>Loading</div>

  const device = data.Device
  const deviceStatus = device.socket ? (
    <>
    <div className="pr-2">
      Online 
    </div> 
    <i className='fas fa-circle text-green-500 mr-2'></i>
    </>
  ) : (
    <>
    <div className="pr-2">
      Offline 
    </div>
    <i className='fas fa-circle text-red-500 mr-2'></i>
    </>
  )
  console.log(`device: ${JSON.stringify(device)}`)
  if (!device) return <>Device not found</>
  console.log(device)
  const macs = (device && device.hardware && device.hardware.macs)? device.hardware.macs: 'N/A'
  return (
    <>
      <div className='w-full'>
      <Modal
          isOpen={modalIsOpen}
          style={modalStyle}
          contentLabel="Example Modal"
        >
          <div className="">
            <div className="text-center pb-2">
              Deregistrare Dispositivo
            </div>
            <div className="">
              <div className="pr-2">
              <button className="flex-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => 
                setIsOpen(false)}>
                  Annulla
              </button>
              </div>
              <div>
              <button className="flex-1 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={()=>{
                unregisterDevice({variables: {deviceId: device._id}})
                }}>
                Unregister
              </button>
              </div>
            </div>
          </div>
        </Modal>
        <div className=''>
          <div
            className={
              'relative flex flex-col min-w-0 break-words w-full mb-6 '
            }
          >
            <div className='rounded-t mb-0 px-4 py-3 border-0'>
              <div className='flex flex-wrap items-center'>
                <div className='flex flex-row justify-between relative w-full px-4 max-w-full flex-grow flex-1'>
                </div>
                <div className='block w-full overflow-x-auto pt-4'>
                    <div className='flex w-full mb-3 items-center'>
                      <div className="flex pl-2 w-1/3">
                        <form className="flex flex-row">
                          <input
                            type='text'
                            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                            placeholder='Nome'
                            value={name}
                            onChange={e => setName(e.target.value)}
                          />
                          <span
                              className="flex items-center bg-gray-100 rounded rounded-l-none border-0 px-3 font-bold text-grey-100">
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={()=>{
                              const variables = {
                                deviceId: device._id,
                                inputData: {
                                  name
                                }
                              }
                              editDevice({variables})
                              }}>
                              Modifica
                            </button>
                          </span>
                        </form>
                      </div>
                      <div className="flex flex-col w-1/3 place-content-center">
                        <label
                        className='uppercase text-gray-700 font-bold'
                        htmlFor='grid-password'
                        >
                          Seriale: {obscurate(device.serial)}
                        </label>
                        <label
                        className='text-gray-700 font-bold pt-4'
                        htmlFor='grid-password'
                        >
                          ID: {device._id}
                        </label>
                        <label
                        className='uppercase text-gray-700 font-bold pt-4'
                        htmlFor='grid-password'
                        >
                          API AUTH CODE: {device.deviceApiAuthCode}
                        </label>
                        <label
                        className='uppercase text-gray-700 font-bold pt-4'
                        htmlFor='grid-password'
                        >
                          SW VERSION: {device?.hardware?.swVersion || 'N/A'}
                        </label>
                        <label
                        className='uppercase text-gray-700 font-bold pt-4'
                        htmlFor='grid-password'
                        >
                          IP: {device?.hardware?.ips} ({macs.toLowerCase()})
                        </label>
                      </div>
                      <div className="flex w-1/3 justify-end">
                      {deviceStatus}
                      </div>
                    </div>
                    <div><PrintersTable device={device}/></div>
                    <div><LogsTable logs={device.logs} /></div>
                  <div className="flex w-full content-center">
                    <div  className="flex-1"></div>
                    <div>
                      <button className="flex-1 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={()=>{
                        setIsOpen(true)
                        }}>
                        Unregister
                      </button>
                    </div>
                    <div  className="flex-1"></div>
                  </div>
                  {/* <div className='block'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-gray-700 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Seriale
                      </label>
                      <input
                        type='text'
                        className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                        placeholder='Seriale'
                        value={seriale}
                        onChange={e => setSeriale(e.target.value)}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

