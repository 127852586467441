import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory, useParams } from 'react-router';
import {ADD_PRINTER_TO_DEVICE} from '../../../graphql/queries'
import { supportedPrinters } from '../../../graphql/types';

function ValidateIPaddress(ipaddress: string) 
{
 if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress))
  {
    return true
  }
return false
}


export default function AddPrinter (props: any) {
  const history = useHistory();
  const { _id }: any = useParams();
  const [name, setName] = useState('')
  const [ip, setIp] = useState('')
  const [port, setPort] = useState('443')
  const [url, setUrl] = useState('')
  const [isSSL, setIsSSL] = useState(true)
  const [isFiscal, setIsFiscal] = useState(true)
  const [automaticClousure, setAutomaticClousure] = useState(false)
  const [brand, setBrand] = useState('')
  const [model, setModel] = useState('')

  const [addPrinter] = useMutation(ADD_PRINTER_TO_DEVICE,{onCompleted: (res) => {
    console.log('Printer added', res)
    if (res && res.devicePrinterAdd && res.devicePrinterAdd._id) {
      history.push(`/device/${_id}`)
    } else {
      console.log('Error in adding a printer')
    }
  }, onError: (err) => console.log(`Error in Adding a printer: ${err}`)});

  console.log(_id)

  const handleChangeBrand = (event: any) => {
    // console.log(event.target.value)
    setBrand(event.target.value)
  }

  // const checkIp = () => {

  // }

  const checkIfComplete = () => {
    if (!ValidateIPaddress(ip)) return `Ip obbligatorio`
    if (brand==='') return `Marca obbligatoria`
    if (model==='') return `Modello obbligatorio`
    return null
  }

  const color = 'light'

  return (
    <>
      <div className='flex flex-wrap w-1/2'>
        <div className='mb-12 px-4'>
          <div
            className={
              'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
              (color === 'light' ? 'bg-white' : 'bg-gray-800 text-white')
            }
          >
            <div className='rounded-t mb-0 px-4 py-3 border-0'>
              <div className='flex flex-wrap items-center'>
                <div className='flex flex-row justify-between relative w-full px-4 max-w-full flex-grow flex-1'>
                  <div
                    className={
                      'font-semibold text-lg ' +
                      (color === 'light' ? 'text-gray-800' : 'text-white')
                    }
                  >
                    Stampante per dispositivo {_id}
                  </div>
                </div>
                <div className='block w-full overflow-x-auto pt-4'>
                <div className='block'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-gray-700 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Nome
                      </label>
                      <input
                        type='text'
                        className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                        placeholder='Nome'
                        value={name}
                        onChange={e => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='block'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-gray-700 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Ip
                      </label>
                      <input
                        type='text'
                        className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                        placeholder='Ip'
                        value={ip}
                        onChange={e => setIp(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='block'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-gray-700 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Port
                      </label>
                      <input
                        type='text'
                        className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                        placeholder='Port'
                        value={port}
                        onChange={e => setPort(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='block'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-gray-700 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Url (lasciare vuoto se non si conosce)
                      </label>
                      <input
                        type='text'
                        className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                        placeholder='url'
                        value={url}
                        onChange={e => setUrl(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='block'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-gray-700 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        SSL
                      </label>
                      <input
                        type='checkbox'
                        className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                        placeholder='SSL'
                        checked={isSSL}
                        onChange={e => setIsSSL(e.target.checked)}
                      />
                    </div>
                  </div>
                  <div className='block'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-gray-700 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        FISCALE
                      </label>
                      <input
                        type='checkbox'
                        className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                        placeholder='FISCALE'
                        checked={isFiscal}
                        onChange={e => setIsFiscal(e.target.checked)}
                      />
                    </div>
                  </div>
                  <div className='block'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-gray-700 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        CHIUSURA AUTOMATICA
                      </label>
                      <input
                        type='checkbox'
                        className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                        placeholder='CHIUSURA AUTOMATICA'
                        checked={automaticClousure}
                        onChange={e => setAutomaticClousure(e.target.checked)}
                      />
                    </div>
                  </div>
                  <div className='block'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-gray-700 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Marca
                      </label>
                      <select className="w-full rounded uppercase" onChange={handleChangeBrand}>
                        <option key="" value="">Marca</option>
                        {supportedPrinters.map((object) => {
                          return <option key={object.brand} value={object.brand}>{object.brand}</option>
                        })}
                      </select>
                    </div>
                  </div>
                  <div className='block'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-gray-700 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Modello
                      </label>
                      <select className="w-full rounded uppercase" onChange={(event) => setModel(event.target.value)}>
                        <option key="" value="">Modello</option>
                        {supportedPrinters.map((object) => {
                          if (object.brand === brand) {
                            return object.models.map((model) => {
                             return <option key={brand+"-"+model} value={model}>{model}</option>
                            })
                          } else return null
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="block flex w-full">
                    {/* <div className="flex-1"></div> */}
                    <div className="flex w-1/2 justify-center">
                      <button disabled={(checkIfComplete()!==null)} className="disabled:opacity-50 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={()=>{
                        console.log(_id, name);
                        const variables = {
                          deviceId: _id,
                          printer: {
                            name,
                            ip,
                            port,
                            url,
                            isSSL,
                            isFiscal,
                            automaticClousure,
                            brand,
                            model
                          }
                        }
                        console.log(variables)
                        addPrinter({variables})
                        }}>
                        Aggiungi
                      </button>
                    </div>
                    <div className="flex w-1/2 justify-center">
                      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={()=>{
                        history.push(`/device/${_id}`)
                        }
                      }>
                        Cancel
                      </button>
                    </div>
                    {/* <div  className="flex-1"></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

