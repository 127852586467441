import {useState} from 'react'
import { ILog } from '../../graphql/types';

// return dd/mm/yyyy hh:mm
const getStringDate = (strDate: string) => {
  const date = new Date(strDate)
  const day = date.getDate()
  const month = date.getMonth()+1
  const year = date.getFullYear()
  const hour = date.getHours()
  const minute = date.getMinutes()
  return `${day}/${month}/${year} ${hour}:${minute}`
}

interface Log {
  _id: string,
  isCompleted: boolean,
  description: string,
  createdAt: string,
  lastUpdate: string
  info: {
    level: string, //info,debug,error
    description: string
  }[]
}

function LogTableRow ({ log, selectRow, openBox }:{log:ILog, selectRow: any, openBox: any}) {
  const logStatus = log.isCompleted ? (
    <>
      <i className='fas fa-circle text-green-500 mr-2'></i>
    </>
  ) : (
    <>
      <i className='fas fa-circle text-red-500 mr-2'></i>
    </>
  )
  return (
    <tr className='group cursor-pointer' key={log._id} onClick={() => {openBox(); selectRow(log)}} >
      <td className='group-hover:bg-gray-500 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 truncate'>
        {log.description}
      </td>
      <td className='group-hover:bg-gray-500 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4'>
        {getStringDate(log.createdAt)}
      </td>
      <td className='group-hover:bg-gray-500 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4'>
        {getStringDate(log.lastUpdate)}
      </td>
      <td className='group-hover:bg-gray-500 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4'>
        {logStatus}
      </td>
    </tr>
  )
}


function SideBox ({selectedRow, closeBox}: {selectedRow: Log | null, closeBox: any}) {
  if (!selectedRow) return (<div></div>)
  console.log(`log: ${selectedRow.info[0]}`)
  const infoRows = (selectedRow.info && selectedRow.info.length > 0)? selectedRow.info.map((info) => {
    console.log(info)
    switch (info.level) {
      case 'auth':
      case 'info':
        return <div className="w-full border-blue-400 rounded border-2 border-opacity-40">{info.description}</div>
      case 'error':
        return <div className="w-full border-red-400 rounded border-2 border-opacity-40">{info.description}</div>
      default:
        return <div className="w-full border-black rounded border-2 border-opacity-40">{info.description}</div>
    }
  }) : "no content"
  return (
    <div className='relative flex flex-col shadow-lg rounded bg-white w-1/3 content-center bg-blue-300 bg-opacity-25'>
      <div className="flex flex-row text-center w-full justify-between">
        <div>
          {selectedRow.description}
        </div>
        <div>
          <i className="fas fa-times cursor-pointer" onClick={() => {console.log("eccomi"); closeBox(true)}}></i>
        </div>
      </div>
      {infoRows}
    </div>
  )
}

export default function LogsTable ({logs}: {logs: ILog[]}) {
  console.log(`LogsTable ${JSON.stringify(logs)}`)
  const [selectedRow,selectRow] = useState<Log | null>(null)
  const [boxVisibility,setBoxVisibility] = useState<boolean>(true)
  
  const closeBox = () => {
    setBoxVisibility(false)
  }

  const openBox = () => {
    setBoxVisibility(true)
  }

  const logRows = (logs)? 
    logs.map((log: any) => {
      return LogTableRow({ log, selectRow, openBox })
    }) : <tr className="text-center">
            <td>Nessun log presente</td>
        </tr>
  return (
    <>
      <div className='flex flex-row'>
        <div
          className={
            'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white'
          }
        >
          <div className='rounded-t mb-0 px-4 py-3 border-0'>
            <div className='flex flex-wrap items-center'>
              <div className='relative w-full px-4 max-w-full flex-grow flex-1'>
                <div
                  className={
                    'font-semibold text-lg text-gray-800'
                  }
                >
                  Logs
                </div>
              </div>
            </div>
          </div>
          <div className='block w-full overflow-x-auto'>
            {/* Projects table */}
            <table className='items-center w-full bg-transparent border-collapse'>
              <thead>
                <tr>
                {/* <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                      (color === 'light'
                        ? 'bg-gray-100 text-gray-600 border-gray-200'
                        : 'bg-gray-700 text-gray-300 border-gray-600')
                    }
                  >
                    _id
                  </th> */}
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200'
                    }
                  >
                    Descrizione
                  </th>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200'
                    }
                  >
                    Data creazione
                  </th>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200'
                    }
                  >
                    Ultima modifica
                  </th>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200'
                    }
                  >
                    Stato
                  </th>
                </tr>
              </thead>
              <tbody>
                  {logRows}
              </tbody>
            </table>
          </div>
        </div>
        { (boxVisibility) ? <SideBox selectedRow={selectedRow} closeBox={closeBox}/>: null}
      </div>
    </>
  )
}