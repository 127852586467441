import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_ALL_DEVICES } from '../graphql/queries';
import { charData, DeviceStatusPieChart} from '../components/vari/chars'

export function Dashboard (props: any) {
  const { loading, error, data, startPolling, stopPolling } = useQuery(GET_ALL_DEVICES,{fetchPolicy:"cache-and-network", onCompleted: ()=>{console.log('ALL_DEVICES_QUERY Completed')}})
  useEffect(() => {
    console.log('startPooling')
    startPolling(5000)
  return () => {
    console.log('stopPooling')
   stopPolling()
    }
  }, [startPolling, stopPolling])
  if (error) {
    console.log(`Error: ${error}`)
    return <div>Error loading players.</div>
  }
  if (loading) return <div>Loading</div>
  console.log(data)

  let charDevicesData : charData = [{
    "id": "online",
    "label": "online",
    "value": 0,
    "color": "#228B22"
  },
  {
    "id": "offline",
    "label": "offline",
    "value": 0,
    "color": "#FF0000"
  }
  ]

  let charPrintersData : charData = [{
    "id": "online",
    "label": "online",
    "value": 0,
    "color": "#228B22"
  },
  {
    "id": "offline",
    "label": "offline",
    "value": 0,
    "color": "#FF0000"
  }
  ]

  data.devices.map((device: {socket: string | null, printers:any}) => {
    if (device.socket) charDevicesData[0].value++
      else  charDevicesData[1].value++
      if (device.printers && device.printers.length > 0) {
        let printers = device.printers
        printers.forEach((printer: any) => {
          if (printer.onlineStatus) charPrintersData[0].value++
            else charPrintersData[1].value++
        })
      }
    return null
  })

  return (
    <>
      <div className='w-full'>
        <div className='w-full text-center text-4xl uppercase'>
          Dashboard
        </div>
        <div className="flex w-full pt-5">
          <div className="w-1/2 h-96">
            <div className="text-center text-2xl">Stato dispositivi</div>
            <DeviceStatusPieChart data={charDevicesData}/>
          </div>
          <div className="w-1/2 h-96">
            <div className="text-center text-2xl">Stato stampanti</div>
            <DeviceStatusPieChart data={charPrintersData}/>
          </div>
          {/* <div className="w-1/3">
          </div> */}
        </div>
      </div>
    </>
  )
}