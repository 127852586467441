import { LOG } from "../../config"
import { DB_AUTH, ACTUAL_AUTH } from "../stores/db/auth"
import { onErrorRefreshTokenLinkCreator } from "./onErrorRefreshTokenLinkCreator"
import {
  decodeToken,
  fetchNewAccessToken,
  isTokenValid,
  logout,
} from "../auths/auth"

const log = LOG.extend("apolloClient")

const onErrorRefreshTokenLink = onErrorRefreshTokenLinkCreator({
  authorizationHeaderKey: "authorization",
  fetchNewAccessToken: async (refreshToken: string) => {
    const tokens = await fetchNewAccessToken(refreshToken)
    return tokens
  },
  getAccessToken: (): string | null | undefined => {
    return ACTUAL_AUTH.accessToken
  },
  getRefreshToken: (): string | null | undefined => {
    return ACTUAL_AUTH.refreshToken
  },
  isAccessTokenValid: (accessToken: string | null | undefined) => {
    return isTokenValid(accessToken)
  },
  isUnauthenticatedError: (graphQLError, operationName) => {
    const { extensions, message } = graphQLError
    log.warn("GRAPHQL QUERY ERROR | " + operationName + " | " + message)
    if (!isTokenValid(ACTUAL_AUTH.accessToken)) {
      log.info(
        operationName + " | ACCESS TOKEN EXPIRED, try to refresh accessToken"
      )
      return true
    }
    if (extensions && extensions.code === "UNAUTHENTICATED") {
      log.info(
        operationName + " | Request UNAUTHENTICATED, try to refresh accessToken"
      )
      return true
    }
    return false
  },
  onSuccessfulRefresh: (tokens) => {
    log.info("Save fetched new acceessToken | " + tokens.accessToken)
    let atDecoded = decodeToken(tokens.accessToken)
    if (!atDecoded || !atDecoded.user?.id) {
      logout()
      log.error("Unable to decode refreshed token")
      return false
    }
    DB_AUTH.set({
      // user: atDecoded.user.id,
      accessToken: tokens.accessToken,
      refreshToken: tokens.refreshToken,
    })
  },
  onFailedRefresh: (doLogout: boolean, error) => {
    log.warn("Failed Refresh Token, logout: " + doLogout)
    if (doLogout) {
      logout()
    }
    log.warn(error)
  },
})

export { onErrorRefreshTokenLink }
