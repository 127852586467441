export const exampleCommandDailyClousure = `{
"commands": [
    {
      "type": "dailyClosure",
      "operator": "1"
    }
  ]
}`

export const exampleCommandOneCoffee = `{
  "operator": "1",
  "operations": [
    {
      "type": "sell",
      "operator": "1",
      "description": "Caffè",
      "quantity": 1,
      "unitPrice": 1.2,
      "department": "",
      "info": ""
    }
  ],
  "paymentOperations": [
    {
      "type": "cash",
      "price": 1.2,
      "operator": "",
      "description": "Pagamento Cash"
    }
  ]
}`

export const exampleCommandReprintLastRecipt = `{
  "commands": [
    {
      "type": "reprintLastReceipt"
    }
  ]
}`

export const exampleNonFiscaleMessage = `{
  "operations": [
      {
        "type": "message",
       "props": {
             "size": 4
        },
        "value": "size4"
      },
      {
        "type": "message",
        "value": "reverse",
        "props": {
            "reverse": true
       }
      },
      {
        "type": "newLine",
        "value": ""
      },
      {
        "type": "message",
       "props": {
             "size": 1
        },
        "value": "size 1"
      },
      {
        "type": "message",
       "props": {
             "underline": true
        },
        "value": "underline"
      },
      {
        "type": "message",
       "props": {
             "emphasize": true
        },
        "value": "emphasize"
      },
      {
        "type": "barCode",
       "props": {
             "barCodeType":  "code39"
        },
        "value": "ABC-1234"
      },
      {
        "type": "cut"
      }
    ]
  }`
