import AdminNavbar from './Navbars/AdminNavbar'
import Sidebar from './Sidebar/Sidebar'
import DeviceTable from './Devices/DevicesTable'
import {
  Route
} from "react-router-dom";
import { useAuthState } from '../libs/auths/auth';
import UpdateDevice from './Devices/UpdateDevice'
import UpdatePrinter from './Devices/Printers/UpdatePrinter'
import RegisterDevice from './Devices/RegisterDevice'
import AddPrinter from './Devices/Printers/AddPrinter'
import { UserProfile } from './User/Profile'
import { Dashboard } from './Dashboard'
import { UsersList } from './Users/UsersList';

export function Home () {
  const userDetails = useAuthState();
  const permissions = userDetails.user?.auth?.permissions || []
  const isRoot = permissions.includes('root')
  console.log('Home userDetails:', userDetails)
  return (
     <>
        <AdminNavbar />
        <Sidebar />
        <div className='relative ml-64 pt-16'>
          <div className='flex items-center justify-center'>
            <div className="flex font-bold rounded-lg h-4/5 w-11/12 items-center justify-center">
              <Route exact path="/">
                <Dashboard />
              </Route>
            <Route exact path="/devices">
                <DeviceTable />
            </Route>
            <Route exact path="/devices/register">
                    <RegisterDevice />
            </Route>
            <Route exact path="/device/:_id/printers/add">
                    <AddPrinter />
            </Route>
            <Route exact path="/device/:_id/printer/:printerId">
                    <UpdatePrinter />
            </Route>
            <Route exact path="/device/:_id">
                    <UpdateDevice />
            </Route> 
            <Route exact path="/user/profile">
                    <UserProfile />
            </Route>
            {
              isRoot && 
                <Route exact path="/users/list">
                      <UsersList />
                </Route>
            }
            </div>
          </div>
        </div>
    </>
  )
}
