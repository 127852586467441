import { useMemo } from "react"
import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { DB_AUTH } from "../stores/db/auth"
import { ENV } from "../../config/index"
import { onErrorRefreshTokenLink } from "./onErrorRefreshTokenLink"

let apolloClient: ApolloClient<NormalizedCacheObject>

const httpLink = createHttpLink({
  uri: ENV.backend.uri,
})

let oertl: any = onErrorRefreshTokenLink

const authLink = setContext((_, { headers }) => {
  // const { accessToken, user } = useAuthState()
  const accessToken = DB_AUTH.get().accessToken
  // const accessToken = ACTUAL_AUTH.accessToken;
  console.log(`authLink accessToken: ${accessToken}`)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `${accessToken}` : "",
    },
  }
})

function createApolloClient() {
  const client = new ApolloClient({
    ssrMode: typeof window === "undefined", // set to true for SSR
    // link: authLink.concat(httpLink),
    link: from([authLink, oertl, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  })
  return client
}

export function initializeApollo(initialState = null) {
  if (!apolloClient) {
    apolloClient = apolloClient ?? createApolloClient()
  }
  return apolloClient
}

export function useApollo(initialState: any) {
  const client = useMemo(() => initializeApollo(initialState), [initialState])
  return client
}

export const getApolloClient = () => {
  return apolloClient
}
