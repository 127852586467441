import { changePassword } from '../../libs/auths/auth';
import { useFormFields } from '../../libs/hooks/forms';

export function ResetPassword (props: any) {
    const location = props.location 
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code') as string;
    const email = urlParams.get('email') as string;
    const [inputs, handleInputChange] = useFormFields<{
      code: string;
      password: string;
    }>({
      code: code,
      password: '',
    }); 
    // const dispatch = useAuthDispatch()
    // const { loading, errorMessage } = useAuthState()
    const handleChangePassword = async  (event: any) => {
      event.preventDefault()
      try {
        let res = await changePassword({email: email, code: inputs.code, password: inputs.password})
        if (res) {
          // console.log('res', res)
          props.history.push('/auth/login')
        }
      } catch (error) {
        console.log('changePassword error', error)
      }
    }

  return (
    <>
  <div className="container max-w-full mx-auto py-24 px-6">
    <div className="font-sans">
      <div className="max-w-sm mx-auto px-6">
        <div className="relative flex flex-wrap">
          <div className="w-full relative">
            <div className="mt-6">
              <div className="text-center font-semibold text-black">
                Stampante Fiscale Login
              </div>
              {/* <div>{errorMessage}</div> */}
              <form className="mt-8">
                <div className="mx-auto max-w-lg">
                  <div className="py-2">
                    <span className="px-1 text-sm text-gray-600">Code</span>
                    <input 
                      className="text-md block px-3 py-2  rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                      id="code"
                      type='text'
                      placeholder='Code'
                      required={true}
                      value={code}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="py-2" x-data="{ show: true }">
                    <span className="px-1 text-sm text-gray-600">Nuova password</span>
                    <div className="relative">
                      <input 
                        className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md 
                                    focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                        id="password"
                        type='password'
                        placeholder='Nuova Password'
                        required={true}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <button 
                    className="mt-3 text-lg font-semibold bg-gray-800 w-full text-white rounded-lg px-6 py-3 block shadow-xl hover:text-white hover:bg-black"
                    type='button'
                    onClick={handleChangePassword}
                  >
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </>
  )
}
