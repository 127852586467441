import {
  logger,
  configLoggerType,
  transportFunctionType,
  consoleTransport,
} from "react-native-logs"

import { theme } from "./theme"

const ENV: {
  app: {
    version: string
    name: string | null
  }
  mode: "development" | "production" | "test"
  backend: {
    uri: string
    APIuri: string
  }
  auth: {
    uri: string
    refreshUri: string
  }
  db: {
    cacheKey: string
    authKey: string
  }
  logs: {
    destination: "local" | "remote"
  }
} = {
  app: {
    version: "0.0.1",
    name: "Stampante Fiscale Frontend",
  },
  mode: "production",
  backend: {
    uri: "https://backend.stampantefiscaleapi.it/graphql",
    APIuri: "https://backend.stampantefiscaleapi.it/api"
  },
  auth: {
    uri: "https://auth.stampantefiscaleapi.it/graphql",
    refreshUri: "https://auth.stampantefiscaleapi.it/refreshToken",
  },
  db: {
    cacheKey: "SF_CACHE",
    authKey: "SF_AUTH",
  },
  logs: {
    destination: "local",
  },
}

const __DEV__: boolean =
  ENV.mode === "development" || ENV.mode === "test" ? true : false

const transport: transportFunctionType = (props) => {
  if (ENV.logs.destination === "local") {
    consoleTransport(props)
  }
}

let loggerConfig: configLoggerType = {
  transport: transport,
  severity: __DEV__ ? "debug" : "error",
  transportOptions: {
    colors: "web",
  },
  levels: {
    debug: 0,
    info: 1,
    warn: 2,
    error: 3,
  },
  async: true,
  dateFormat: "time",
  printLevel: true,
  printDate: true,
  enabled: true,
  enabledExtensions: ["auth", "auth.DB", "apolloClient", "storages"],
}

const LOG = logger.createLogger(loggerConfig)

LOG.debug("LOGGER CREATED")

if (__DEV__) {
  LOG.debug(`Dashboard started in ${ENV.mode} mode`)
}

export { __DEV__, ENV, LOG, theme }
