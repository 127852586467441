export function Alert ({type, close = false, value}:{type: 'danger'|'info'|'warning', close?: boolean, value: {title?: string, content?: string}}) {
  if (close) return (null)
  if (!value.title && !value.content) return (null)
  let format = ''
    switch (type) {
      case 'danger':
        format = 'bg-red-200 border-red-600 text-red-600 border-l-4 p-4'
      break;
      case 'warning':
        format = 'bg-yellow-200 border-yellow-600 text-yellow-600 border-l-4 p-4'
      break;
      case 'info':
        format = 'bg-blue-200 border-blue-600 text-blue-600 border-l-4 p-4'
      break;
      default:
        format = ''
    }
  return (
    <div className={'rounded-lg ' + format} role="alert">
      <p className="font-bold">
            {value?.title}
      </p>
      <p>
          {value?.content}
      </p>
    </div>
  )
}