export const supportedPrinters = [
  {
    brand: "epson",
    models: [
      "FP-81 II",
      "FP-81 II S",
      "FP-81 II T",
      "FP-90 III",
      "FP-81 II RT",
      "FP-90 III RT",
      "TM-T20II",
      "TM-T20III",
    ],
  },
  {
    brand: "rch",
    models: ["PRINT F!"],
  },
  {
    brand: "axon",
    models: ["RT-HYDRA II"],
  },
  {
    brand: "custom",
    models: ["K3F-N"],
  }  
]

export interface IUser {
  id: string
  email: string
  userApiAuthCode: string
  company: {
    name: string
    location: {
      city: string
      postCode: string
      nation: string
      street: string
    }
    vat: string
  }
}

export interface IDevice {
  _id: string
  deviceApiAuthCode: string
  name: string
  serial: string
  hardware?: {
    ips?: string
    hostname?: string
  }
  socket: string
  printers: IPrinter[]
  logs: ILog[]
}

export interface IPrinter {
  _id: string
  name: string
  onlineStatus: boolean
  brand: string
  model: string
  automaticClousure: boolean
  ip: string
  port: string
  url: string
  isSSL: boolean
  isFiscal: boolean
  logs: ILog[]
  printerAuth: {
    username: string
    password: string
  }
}

export interface IPrinterInput {
  name: string
  ip: string
  port: string
  url: string
  isSSL: boolean
  brand: string
  model: string
  printerAuth: {
    username: string
    password: string
  }
}

export interface ILog {
  _id: string
  description: string
  isCompleted: boolean
  createdAt: string
  lastUpdate: string
  info: {
    level: LogLevels
    description: string
  }
}

export enum LogLevels {
  auth = "auth",
  info = "info",
  warning = "warning",
  error = "error",
  debug = "debug",
}
