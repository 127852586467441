import { Link } from 'react-router-dom';
import { useAuthDispatch, useAuthState, loginUser } from '../../libs/auths/auth';
import { useFormFields } from '../../libs/hooks/forms';

export function Login (props: any) {
    const [inputs, handleInputChange] = useFormFields<{
      email: string;
      password: string;
    }>({
      email: '',
      password: '',
    }); 
    const dispatch = useAuthDispatch()
    const { errorMessage } = useAuthState()
    const handleLogin = async  (event: any) => {
      event.preventDefault()
      try {
        let res = await loginUser(dispatch, {...inputs})
        if (res) {
          props.history.push('/')
        }
      } catch (error) {
        dispatch({
          type: "LOGIN_ERROR",
          error: "Errore generale, contattare amministratore di sistema",
        })
        console.log('handleLogin error', error)
      }
    }
  return (
    <>
  <div className="container max-w-full mx-auto px-6">
    <div className="font-sans">
      <div className="max-w-sm mx-auto px-6">
        <div className="relative flex flex-wrap">
          <div className="w-full relative">
            <div className="mt-6">
              <div className="text-center">
                <img src="logo512.png" alt="Stampante Fiscale API"></img>
              </div>
              <div>{errorMessage}</div>
              <form className="">
                <div className="mx-auto max-w-lg">
                  <div className="py-2">
                    <span className="px-1 text-sm text-gray-600">Email</span>
                    <input 
                      className="text-md block px-3 py-2  rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                      id="email"
                      type='email'
                      placeholder='Email'
                      autoComplete="username"
                      required={true}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="py-2" x-data="{ show: true }">
                    <span className="px-1 text-sm text-gray-600">Password</span>
                    <div className="relative">
                      <input 
                        className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md 
                                    focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                        id="password"
                        type='password'
                        placeholder='Password'
                        autoComplete="current-password"
                        required={true}
                        onChange={handleInputChange}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            handleLogin(event)
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    {/* <label className="block text-gray-500 font-bold my-4">
                      <input 
                        type="checkbox"
                        className="leading-loose text-pink-600"
                      /> 
                      <span className="py-2 text-sm text-gray-600 leading-snug"> Remember Me </span>
                    </label> */}
                    <label className="block text-gray-500 font-bold my-4">
                      <Link to="/user/askResetPassword"><span>Password dimenticata?</span></Link>
                      {/* <a
                        href="/user/askResetPassword"
                        className="cursor-pointer tracking-tighter text-black border-b-2 border-gray-200 hover:border-gray-400">
                      </a> */}
                    </label>
                  </div> 
                  <button 
                    className="mt-3 text-lg font-semibold bg-gray-800 w-full text-white rounded-lg px-6 py-3 block shadow-xl hover:text-white hover:bg-black"
                    type='button'
                    onClick={handleLogin}
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </>
  )
}
