import { ENV, LOG } from "../../config"

const log = LOG.extend("storages")

const DB = localStorage
const cacheStorage = localStorage

const D = "__"

const envKey = (env: string, key: string) => env + D + key
const appeKey = (key: string) => key

// storage legato all'ambiente (test o production)
const envStorage = {
  store: DB,
  getObject: (key: string) => {
    let result = null
    try {
      let strResult: any = DB.getItem(envKey(ENV.mode, key))
      result = JSON.parse(strResult)
    } catch (error) {
      log.error(
        "envStorage getObject " + envKey(ENV.mode, key) + " | " + error?.message
      )
    }
    return result
  },
  setObject: (key: string, item: Object) => {
    let strItem = JSON.stringify(item)
    let result = DB.setItem(envKey(ENV.mode, key), strItem)
    return result
  },
  remove: (key: string) => {
    let result = DB.removeItem(envKey(ENV.mode, key))
    return result
  },
}

// storage globale app
const appStorage = {
  store: DB,
  getObject: (key: string) => {
    let result = null
    try {
      let item: any = DB.getItem(appeKey(key))
      result = JSON.parse(item)
    } catch (error) {
      log.error("appStorage getObject " + appeKey(key) + " | " + error)
    }
    return result
  },
  setObject: (key: string, item: Object) => {
    let result = null
    try {
      result = result = DB.setItem(appeKey(key), JSON.stringify(item))
    } catch (error) {
      log.error("appStorage getObject " + appeKey(key) + " | " + error)
    }
    return result
  },
  remove: (key: string) => {
    let result = DB.removeItem(appeKey(key))
    return result
  },
}

export { cacheStorage, envStorage, appStorage }
