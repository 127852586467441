import { useQuery } from "@apollo/client"
import { GET_ALL_USERS } from "../../graphql/queries"
import { impersonateUser, useAuthDispatch, useAuthState } from "../../libs/auths/auth"

export function UsersList (props: any) {
  const { loading, error, data } = useQuery(GET_ALL_USERS,{fetchPolicy:"cache-and-network", onCompleted: ()=>{console.log('ALL_USERS_QUERY Completed')}})

  const dispatch = useAuthDispatch()
  const { errorMessage } = useAuthState()

  if (loading) {
    return <div>Loading</div>
  }

  if (error) {
    console.log(`Error: ${error}`)
    return <div>Error loading users.</div>
  }

  console.log(`users: ${JSON.stringify(data.allUsers)}`)

  const handleImpersonate = async  (id: string) => {
    try {
      let res = await impersonateUser(dispatch, {id})
      if (res) {
        props.history.push('/')
      }
    } catch (error) {
      dispatch({
        type: "LOGIN_ERROR",
        error: "Errore generale, contattare amministratore di sistema",
      })
      console.log('handleLogin error', error)
    }
  }

  return (
    <>
      <div className=''>
        <div className='flex items-center justify-center'>
          Lista utenti
        </div>
        {
          data.allUsers.map((user: any) => {
            return (
              <div key={user.id} className='flex flex-row items-center justify-between text-sm'>
                <div className='flex flex-row items-center space-x-2'>
                    <div className='font-medium text-gray-900'>
                      {user.id}
                    </div>
                    <div className='text-gray-500'>
                      {user.email}
                    </div>
                  <div className='text-gray-500 uppercase'>
                    {user.company.name}
                  </div>
                  <div className="text-red-500 cursor-pointer" onClick={() => handleImpersonate(user.id)}>
                    Impersona
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </>
  )
}