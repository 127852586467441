import { Redirect, Route } from "react-router-dom"
import { useAuthState } from "../../libs/auths/auth";


export const AppRoutes = ({ component: Component, path, isPrivate, ...rest }:any) => {
  const userDetails = useAuthState();
  return (
    <Route
      path={path}
      render={(props) =>
        isPrivate && !Boolean(userDetails.accessToken) ? (
          <Redirect to={{ pathname: "/auth/login" }} />
        ) : (
          <Component {...props} />
        )
      }
      {...rest}
    />
  )
}

export default AppRoutes
