import { ENV, LOG } from "../../../config"
import { envStorage } from "../../storages"

const log = LOG.extend("auth.DB")
export interface Auth {
  user?: {
    auth?: {
      isActive: boolean
      isEmailVerified: boolean
      permissions: string[]
    }
    id?: string
    email?: string
    company?: {
      name: string
      location: {
        city: string
        postCode: string
        nation: string
        street: string
      }
      vat: string
    }
  }
  accessToken: string
  refreshToken: string
}

let ACTUAL_AUTH_INIT: Auth = {
  user: {},
  refreshToken: "",
  accessToken: "",
}

let ACTUAL_AUTH: Auth = ACTUAL_AUTH_INIT

const DB_AUTH = {
  get: () => {
    let authSaved: Auth | null = null
    try {
      authSaved = envStorage.getObject(ENV.db.authKey)
    } catch ({ message }) {
      log.error(`Get DB_AUTH ERROR: ${message}`)
    }
    if (authSaved) {
      ACTUAL_AUTH = authSaved
    } else {
      log.warn("No DB_AUTH found")
      ACTUAL_AUTH = { ...ACTUAL_AUTH_INIT }
    }
    return ACTUAL_AUTH
  },
  set: (params: Auth) => {
    ACTUAL_AUTH = {
      ...ACTUAL_AUTH,
      ...params,
    }
    try {
      envStorage.setObject(ENV.db.authKey, ACTUAL_AUTH)
    } catch ({ message }) {
      log.error(`Set DB_AUTH ERROR: ${message}`)
    }
    return ACTUAL_AUTH
  },
  clear: () => {
    log.debug("CLEAR AUTHPARAM")
    ACTUAL_AUTH = { ...ACTUAL_AUTH_INIT }
    try {
      envStorage.remove(ENV.db.authKey)
    } catch ({ message }) {
      log.error(`Remove DB_AUTH ERROR: ${message}`)
    }
    return ACTUAL_AUTH
  },
}

export { DB_AUTH, ACTUAL_AUTH }
