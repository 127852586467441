import { Login } from "../views/auth/Login"
import { NotFound } from "../components/routes/NotFound"
import { Logout } from "../views/auth/Logout"
import { ResetPassword } from "../views/auth/ResetPassword"
import { AskResetPassword } from "../views/auth/AskResetPassword"
import { Home } from "../views/Home"

export const routes = [
  {
    path: "/auth/login",
    component: Login,
    isPrivate: false,
  },
  {
    path: "/user/resetPasswordCode",
    component: ResetPassword,
    isPrivate: false,
  },
  {
    path: "/user/askResetPassword",
    component: AskResetPassword,
    isPrivate: false,
  },
  {
    path: "/auth/logout",
    component: Logout,
    isPrivate: false,
  },
  {
    path: "/",
    component: Home,
    isPrivate: true,
  },
  {
    path: "/*",
    component: NotFound,
    isPrivate: true,
  },
]
