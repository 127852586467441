import { useHistory } from "react-router";
import { Link } from 'react-router-dom';

function PrinterTableRow ({ printer, device }:{printer:any, device: any}) {
  let history = useHistory();
  const redirect : any = () => {
    history.push(`/device/${device._id}/printer/${printer._id}`)
  }
  console.log(printer)
  const automClousure = printer.automaticClousure ? (
    <>
      <i className='fas fa-circle text-green-500 mr-2'></i>
    </>
  ): (
    <>
      <i className='fas fa-circle text-red-500 mr-2'></i>
    </>
  )
  const printerStatus = printer.onlineStatus ? (
    <>
      <i className='fas fa-circle text-green-500 mr-2'></i> online
    </>
  ) : (
    <>
      <i className='fas fa-circle text-red-500 mr-2'></i> offline
    </>
  )
  const printerType = printer.isFiscal ? 'Fiscale' : 'Non fiscale'
  return (
    <tr className='group cursor-pointer' key={printer._id}  onClick={() => redirect()}>
      <td className='group-hover:bg-gray-500 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4'>
          {printer._id}
      </td>
      <td className='group-hover:bg-gray-500 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4'>
          {printer.name}
      </td>
      <td className='group-hover:bg-gray-500 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4'>
          {printer.brand} - {printer.model}
      </td>
      <td className='group-hover:bg-gray-500 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4'>
          {printer.ip}
      </td>
      <td className='group-hover:bg-gray-500 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4'>
          {printerType}
      </td>
      <td className='group-hover:bg-gray-500 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4'>
          {automClousure}
      </td>
      <td className='group-hover:bg-gray-500 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4'>
        {printerStatus}
      </td>
    </tr>
  )
}

export default function PrintersTable (device: any) {
  device = device.device
  console.log(`PrintersTable ${JSON.stringify(device)}`)

  const printerRows = (device && device.printers)? 
    device.printers.map((printer: any) => {
      return PrinterTableRow({ printer , device })
    }) : <tr className="text-center">
      <td>Nessuna stampante presente</td>
      </tr>
  console.log(device.printers)
  const printerAddLink = 
    <Link to={`/device/${device._id}/printers/add`} >
      <i className='fas text-lg fa-plus-circle cursor-pointer'></i>
    </Link>
  return (
    <>
      <div
        className={
          'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded '
        }
      >
        <div className='rounded-t mb-0 px-4 py-3 border-0'>
          <div className='flex flex-wrap items-center'>
            <div className='relative w-full px-4 max-w-full flex-grow flex-1'>
              <div
                className={
                  'font-semibold text-lg '
                 
                }
              >
                Stampanti
              </div>
            </div>
            <div>
             {printerAddLink}
            </div>
          </div>
        </div>
        <div className='block w-full overflow-x-auto'>
          {/* Projects table */}
          <table className='items-center w-full bg-transparent border-collapse'>
            <thead>
              <tr>
                <td
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' 
                  }
                >
                  Id
                </td>
                <td
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' 
                  }
                >
                  Nome
                </td>
                <td
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left '
                  }
                >
                  Marca - Modello
                </td>
                <td
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left '
                  }
                >
                  ip
                </td>
                <td
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' 
                  }
                >
                  TIPO
                </td>
                <td
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' 
                  }
                >
                  CHIUS. AUTOM.
                </td>
                <td
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' 
                  }
                >
                  Stato
                </td>
              </tr>
            </thead>
            <tbody>
                {printerRows}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}