import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router';
import {REGISTER_DEVICE} from '../../graphql/queries'
import {Alert} from '../../components/vari/alert'

export default function RegisterDevice (props: any) {
  const history = useHistory();
  const [serial, setSerial] = useState('')
  const [valid, setValid] = useState(false)
  const [error, setError] = useState({
    content: "",
    title: ""
  })

  const toggleSerial = (serial: string) => {
    setSerial(serial)
    setValid((serial !== ''))
  }

  const [registerDevice] = useMutation(REGISTER_DEVICE,{onCompleted: (res) => {
    if (res && res.deviceRegister && res.deviceRegister._id) {
      history.push(`/device/${res.deviceRegister._id}`)
    } else {
      console.log('Error in registering device')
      setError({content: "Numero seriale errato", title:""})
    }
  }, onError: (err) => {
      console.log(`Error in Registering device: ${err}`);
      setError({content: "Riprovare piu' tardi", title:"Errore imprevisto"})
    }
  });
  return (
    <>
      <div className='flex flex-wrap'>
        <div className='w-full mb-12 px-4'>
          <div
            className={
              'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded'
            }
          >
            <div className='rounded-t mb-0 px-4 py-3 border-0'>
              <div className='flex flex-wrap items-center'>
                <div className='relative w-full px-4 max-w-full flex-grow flex-1'>
                  <div
                    className={
                      'font-semibold text-lg text-gray-800'
                    }
                  >
                    Registrazione dispositivo
                  </div>
                  <div>
                    <Alert type="danger" value={error}/>     
                  </div>
                </div>
                <div className='block w-full overflow-x-auto pt-4'>
                  <div className='block'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-gray-700 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Seriale
                      </label>
                      <input
                        type='text'
                        className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                        placeholder='Nome'
                        value={serial}
                        onChange={e => {toggleSerial(e.target.value);}}
                      />
                    </div>
                  </div>
                  <div className="block flex w-full content-center">
                    <div className="flex-1"></div>
                    <div>
                      <button disabled={!valid} className="disabled:opacity-50 flex-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={()=>{
                        const variables = {
                          serial: serial
                        }
                        registerDevice({variables})
                        }}>
                        Registra
                      </button>
                    </div>
                    <div  className="flex-1"></div>
                  </div>
                  {/* <div className='block'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-gray-700 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Seriale
                      </label>
                      <input
                        type='text'
                        className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                        placeholder='Seriale'
                        value={seriale}
                        onChange={e => setSeriale(e.target.value)}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

