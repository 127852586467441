import React from 'react'
import { useLocation } from 'react-router-dom'
import {Link} from 'react-router-dom'
import {version} from '../../../package.json';
import { DB_AUTH } from '../../libs/stores/db/auth';

export default function Sidebar () {
  const [collapseShow, setCollapseShow] = React.useState('hidden')
  const router = useLocation()
  const permissions = DB_AUTH.get().user?.auth?.permissions || []
  const isRoot = permissions.includes('root')

  return (
    <>
      <nav className='text-left md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6'>
        <div className='md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto'>
          {/* Toggler */}
          <button
            className='cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
            type='button'
            onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}
          >
            <i className='fas fa-bars'></i>
          </button>
          {/* Brand */}
          <Link to="/">
            <img src="logoTesto192.png" alt="Stampante Fiscale API"></img>
          </Link>
          {/* User */}
          {/* <ul className='md:hidden items-center flex flex-wrap list-none'>
            <li className='inline-block relative'>
              <NotificationDropdown />
            </li>
            <li className='inline-block relative'>
              <UserDropdown />
            </li>
          </ul> */}
          {/* Collapse */}
          <div
            className={
              ' md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className='md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300'>
              <div className='flex flex-wrap'>
                <div className='w-6/12'>
                <Link to="/">                  
                      STAMPANTE FISCALE
                    </Link>
                </div>
                <div className='w-6/12 flex justify-end'>
                  <button
                    type='button'
                    className='cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
                    onClick={() => setCollapseShow('hidden')}
                  >
                    <i className='fas fa-times'></i>
                  </button>
                </div>
              </div>
            </div>

            {/* Divider */}
            <hr className='my-4 md:min-w-full' />
            {/* Heading */}
            <h6 className='md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline'>
              Dispositivi
            </h6>
            {/* Navigation */}

            <ul className='md:flex-col md:min-w-full flex flex-col list-none'>
            <Link to="/devices">
              <li className={'items-center ' +
                        (router.pathname.indexOf('/device') !==
                        -1
                          ? 'text-blue-400'
                          : '')}>
                    <i
                      className={
                        'fas fa-tv mr-2 text-sm '
                      }
                    ></i>{' '}
                    Lista Dispositivi
              </li>
            </Link>
            {
              isRoot && (
                <>
                  {/* Divider */}
                  <hr className='my-4 md:min-w-full' />
                  {/* Heading */}
                  <h6 className='md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline'>
                    Utenti (Root)
                  </h6>
                  {/* Navigation */}
                  <Link to="/users/list">
                    <li className={'items-center ' +
                              (router.pathname.indexOf('/users') !==
                              -1
                                ? 'text-blue-400'
                                : '')}>
                          <i
                            className={
                              'fas fa-users mr-2 text-sm '
                            }
                          ></i>{' '}
                          Lista Utenti
                    </li>
                  </Link>
                </>
              )
            }
            </ul>
            <div className="">
              <hr className='my-4 md:min-w-full' />
              <h6 className='md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline'>
                Utente
              </h6>
              <ul className='md:flex-col md:min-w-full flex flex-col list-none'>
              <Link to="/user/profile">
                <li className={'items-center ' +
                          (router.pathname.indexOf('/user/profile') !==
                          -1
                            ? 'text-blue-400'
                            : '')}>
                      <i
                        className={
                          'fas fa-user mr-2 text-sm '
                        }
                      ></i>{' '}
                      Profilo
                </li>
              </Link>
              <Link to="/auth/logout">
                <li className={'items-center '}>
                      <i
                        className={
                          'fas fa-sign-out-alt mr-2 text-sm '
                        }
                      ></i>{' '}
                      Logout
                </li>
              </Link>
              </ul>
            </div>
          </div>
          <div className="flex flex-col text-xs">
              <hr className='my-4 md:min-w-full' />
              <div className="flex justify-center">version: {version}</div>
            </div>
        </div>
      </nav>
    </>
  )
}
